.filters-register {
  .ant-collapse, .ant-collapse-content {
    background-color: unset;
  }

  .filter-title {
    position: relative;

    &.filter-title-activated {
      color: rgba(10,10,250,.9);
    }
  }

  .badge-filter {
    float: right;
    margin-right:20px;
    margin-top:-2px;
  }
  .ant-collapse-content-box {
    padding: 2px 0px !important;
  }
  .ant-collapse-header {
    padding: 7px 0px !important;
    color: rgba(0, 0, 0, .6) !important;
    font-size: 0.9em;
    font-weight: 500;

    .ant-collapse-arrow {
      right: 3px !important;
    }
  }

  .filters-head {
    border-bottom: 1px rgba(0, 0, 0, .1) solid;
    font-size: 0.9em !important;
    height: 30px;
    line-height: 11px;
    overflow: hidden;
    margin-bottom: 20px;

    h4 {
      color: rgba(0, 0, 0, .6);
      float: left;
    }

    .clear-all {
      float: right;
    }
  }
  .filter {
    color: rgba(0, 0, 0, .5);
    margin-bottom: 10px;

    h5 {
      color: rgba(0, 0, 0, .6);
      float: left;
      font-size: 0.9em;
      line-height: 0;
      margin: 0 0 10px;
      padding: 5px 0;
    }

    .clear {
      float: right;
      font-size: 0.9em;
      line-height: 0.9em;
    }

    ul {
      border-radius: 5px !important;
      clear: both;
      list-style-type: none;
      margin: 0;
      overflow: hidden;
      padding: 0;

      li {
        background: rgba(0, 0, 0, .035);
        font-size: 0.9em;
        margin-bottom: 2px;
        padding: 10px;
        text-indent: 5px;

        &:first-child {
          border-top: 1px rgba(255, 255, 255, .4) solid;
        }

        &:last-child {
          border-bottom: 1px rgba(0, 0, 0, .1) solid;
          border-bottom-left-radius: 5px;
          border-bottom-right-radius: 5px;
        }

        .ant-switch {
          float: right;
        }

        &.falsos-positivos {
          background-color: rgba(255, 0, 0, .075);
        }
      }
    }
  }
  .subclient-dropdown {
    position: relative;
    //right: 20px;
    top: 0px;
    left: 0px;
    width: 100%;

    img {
      height: 20px !important;
      //float: left;
      //margin-right: 20px;
    }

    .subclient-logo {
      //background: url('./subclient-placeholder-img.png') no-repeat;
      border-radius: 10px;
      float: left;
      height: 20px;
      margin-right: 0;
      overflow: hidden;
      position: relative;
      top: 5px;
      width: 20px;
    }
  }
}
