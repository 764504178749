.reporting-content{
    .reporting-tabs{
        .ant-tabs-tab{
            background-color: transparent !important;
            // color: white !important;
            margin: 0;
        }
        // .ant-tabs-tab-active{
        //     color: #fff;
        //     background-color: #a1a4a6;
        // }
    }
    .ant-tabs-bar{
        margin:unset;
    }
}