.tab-onboarding-stakeholder{
    padding-left: 20px;
    .section{
        background-color: white;
        border-radius: 7px;
        padding: 20px 20px 10px 20px;
        height: 100%;
    }

    .icon-wrapper-onb-tab{
        display: flex;
        align-items: center;
        justify-content: left;
        
    }

    .spin-wrapper{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        padding:54px;
    }

    .ant-table-tbody{
        font-size: 13px;
    }
}