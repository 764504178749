.modal-alert-card{
    .tasks-creator-section{
        padding: 10px;

        .tasks-creator-body{
            padding: 20px;

            .tasks-creator-content-wrapper{
                border: 1px solid #d9d9d9;
                border-radius: 5px;
                padding: 25px;

                ul:first-child{
                    list-style: none;
                    margin-left: -40px;
                }

                .tasks-creator-task-wrapper{
                    border: 1px solid #0A2D44;
                    border-radius: 5px;
                    padding: 20px;
                    margin-bottom: 20px;
                    .task-wrapper-header{
                        background-color: #0A2D44;
                        color: white;
                        border-radius: 5px;
                        height: 24px;
                        .ant-col{
                            line-height: 24px;
                        }
                    }
                    .task-wrapper-body{
                        padding: 20px;
                    }
                }

                .panel-form-tasks-creator-wrapper{
                    padding: 22px 0px 0px 0px;
                    .panel-form-tasks{
                        background-color: #d9d9d948;
                        border-radius: 10px;
                        padding: 20px;
                    }
                }
            }
        }
    }
}