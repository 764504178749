.batch {
  .break {
    flex-basis: 100%;
    height: 0;
  }

  .no-results {
    align-items: center;
    bottom: 0;
    display: flex;
    justify-content: center;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;

    .no-results-img-wrapper {
      height: 500px;
      margin-top: 50px;
      text-align: center;
      width: 500px;

      img {
        display: block;
        height: 300px;
        margin: 0 auto 20px;
      }

      h3 {
        font-size: 1.8em !important;
      }
    }
  }

  .empty {
    background: url('./empty-arrow.png') no-repeat 250px 60px;
    bottom: 36px;
    left: 36px;
    position: absolute;
    right: 36px;
    top: 36px;

    h3 {
      color: rgba(0, 0, 0, .4);
      font-size: 2em;
      position: absolute;
      text-align: center;
      top: 330px;
      width: 100%;

      span {
      }
    }
  }
}
