.large-card {

  .ant-card {
    margin-top: 15px;
    border-radius: 7px;

    .card-header {

      padding-top: 16px;

      .card-title {
        font-weight: 700;
        font-size: 16px;
      }
    }

    .ant-card-head {
      border-bottom: 0px !important;
    }

    .ant-card-body {
      padding: 0px 10px 10px 10px;
    }
  }


}