.conflicts-of-interest {
	.collection {
		.collection-header {
			background: rgba(0, 0, 0, .3);
			box-shadow: inset 0px 10px 18px -10px rgba(0,0,0,0.25);
			color: #fff;
			height: 40px;
			line-height: 40px;
			-webkit-box-shadow: inset 0px 10px 18px -10px rgba(0,0,0,0.25);
			-moz-box-shadow: inset 0px 10px 18px -10px rgba(0,0,0,0.25);
		}

		.collection-body {
			margin-bottom: -20px;
			min-height: 700px;

			.items {
				.item {

				}
			}
		}
	}
}
