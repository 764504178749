.cake-risks {
  background: rgba(255, 255, 255, .7);
  margin: -20px 0 0;
  overflow: hidden;

  .cake {
    background: #fff;
    height: 155px;
    float: left;
    padding: 10px;
    position: relative;
    width: 159px;

    .cake-is-loading {
      .ant-spin {
        left: 50%;
        margin: -10px 0 0 -10px;
        position: absolute;
        top: 50%;
      }
    }

    .results-number {
      color: rgba(0, 0, 0, .7);
      font-size: 1.5em;
      font-weight: 900;
      position: absolute;
      text-align: center;
      top: 42px;
      width: 100%;
    }

    .results-label {
      color: rgba(0, 0, 0, .7);
      font-size: 0.8em;
      position: absolute;
      text-align: center;
      top: 68px;
      width: 100%;
    }

    .totalNum {
      font-size: 2em;
      margin-top: -0.8em;
      position: absolute;
      left: 0;
      text-align: center;
      top: 50%;
      width: 100%;
    }
  }

  .risk-block {
    $color-active: rgba(0, 0, 0, .02);
    $color-inactive: rgba(0, 0, 0, .07);

    background: rgba(0, 0, 0, .05);
    border-right: 1px rgba(0, 0, 0, .1) solid;
    height: 155px;
    width: 155px;
    float: left;
    position: relative;

    &.is-not-checked {
      -webkit-box-shadow: inset 0px 0px 30px -10px rgba(0, 0, 0, .07);
      -moz-box-shadow: inset 0px 0px 30px -10px rgba(0, 0, 0, .07);
      box-shadow: inset 0px 0px 30px -10px rgba(0, 0, 0, .07);
      animation-duration: 0.5s;
      animation-fill-mode: forwards;
      animation-name: darker-background-color;

      @keyframes darker-background-color {
        from {
          background-color: $color-active;
        }

        to {
          background-color: $color-inactive;
        }
      }

      .percent {
        animation-duration: 0.5s;
        animation-fill-mode: forwards;
        animation-name: percent-fade-out;

        @keyframes percent-fade-out {
          from {
            opacity: 1;
          }

          to {
            opacity: 0;
          }
        }
      }

      .percent-locked {
        animation-duration: 1s;
        animation-fill-mode: forwards;
        animation-name: percent-locked-fade-in;

        @keyframes percent-locked-fade-in {
          from {
            opacity: 0;
          }

          to {
            opacity: 1;
          }
        }
      }

      .circle,
      .risk-level-name {
        animation-duration: 0.5s;
        animation-fill-mode: forwards;
        animation-name: fade-out-childs;

        @keyframes fade-out-childs {
          from {
            opacity: 1;
          }

          to {
            opacity: 0.6;
          }
        }
      }
    }

    &.is-checked {
      animation-duration: 0.5s;
      animation-fill-mode: forwards;
      animation-name: lighter-background-color;
      position: relative;

      @keyframes lighter-background-color {
        from {
          background-color: $color-inactive;
        }

        to {
          background-color: $color-active;
        }
      }

      .percent {
        animation-duration: 0.5s;
        animation-fill-mode: forwards;
        animation-name: percent-fade-in;

        @keyframes percent-fade-in {
          from {
            opacity: 0;
          }

          to {
            opacity: 1;
          }
        }
      }

      .percent-locked {
        animation-duration: 1s;
        animation-fill-mode: forwards;
        animation-name: percent-locked-fade-out;

        @keyframes percent-locked-fade-out {
          from {
            opacity: 1;
          }

          to {
            opacity: 0;
          }
        }
      }

      .circle,
      .risk-level-name {
        animation-duration: 0.5s;
        animation-fill-mode: forwards;
        animation-name: fade-in-childs;

        @keyframes fade-in-childs {
          from {
            opacity: 0.6;
          }

          to {
            opacity: 1;
          }
        }
      }
    }

    &:last-child {
      border-right: 0;
    }

    .top-bar {
      overflow: hidden;
      padding: 30px 10px;

      .ant-switch {
        float: right;
        position: relative;
      }
    }

    span {
      left: 10px;
      position: absolute;
      top: 27px;
    }

    .bottom-label {
      bottom: 7px;
      position: absolute;
      text-align: center;
      width: 100%;
    }

    .inner-circle {
      background: rgba(0, 0, 0, .3);
      border-radius: 45px;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 90px;
      left: 50%;
      margin: -45px 0 0 -45px;
      position: absolute;
      top: 50%;
      width: 90px;
    }
  }
}
