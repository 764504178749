
.managed-alert-card {


  .resume-alert-info {

    .ant-col {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-top: 20px;

      .content {
        height: 120px;

        .ant-spin {
          position: absolute;
          top: 50%;
          left: 50%;
          -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%)
        }
      }
    }

    .ant-progress-circle {
      cursor: pointer;
    }

    .pie-wrapper-dashboard {
      width: 60%;

      div.na {
        text-align: center;
        padding: 50px;
        width: 125px;
        height: 125px;
        font-weight: 800;
        vertical-align: middle;
        border: 1px solid rgba(0, 0, 0, .4);
        border-radius: 62px;
      }
    }

    .managed-alert-resume {

      .alert-title {
        font-size: 16px;
        font-weight: bolder;
        padding-top: 14px;
        color: #06068a;
      }

      .alert-detail {
        color: #06068a;
        padding-top: 6px;
      }
    }
  }


}
