.modal-change-password {
  width: 300px !important;

  &.normal {
    .ant-modal-header {
      //background: grey;
    }
  }

  &.forced {
    .ant-modal-close {
      display: none;
    }

    .ant-modal-header {
      background: red;
    }
  }

  .new-pass-input-group {
    padding-top: 0;

    .ant-form-item {
      margin-bottom: 5px;
    }
  }
}
