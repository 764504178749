.results-info {
  float: left;
  font-size: 1.5em;
  font-weight: bold;
  height: 45px;
}

h3.query-results {
  line-height: 45px;
  font-size: 1.1em;
  height: 40px;
  margin: 0;
  padding: 0;
  width: 100% !important;
}
