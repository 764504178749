.new-item-modal {
  background: #fff;
  border-radius: 10px !important;
  overflow: hidden !important;
  -webkit-box-shadow: 0px 0px 79px -5px rgba(0,0,0,0.75);
  -moz-box-shadow: 0px 0px 79px -5px rgba(0,0,0,0.75);
  box-shadow: 0px 0px 79px -5px rgba(0,0,0,0.75);
  position: relative;
  padding: 0 !important;
  width: 800px !important;

  .ant-modal-close {
    color: rgba(255, 255, 255, .5);
    position: absolute;
    top: -10px !important;
    right: -7px !important;
    padding: 0 !important;
    width: auto !important;
    height: auto !important;
    line-height: auto !important;
    z-index: 20 !important;

    .anticon {
    }
  }

  .ant-form-item {
    margin-bottom: 0;
  }

  .ant-modal-body {
    overflow: hidden;
    padding: 0;

    h3 {
      background: rgba(0, 0, 0, .4);
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
      color: rgba(255, 255, 255, .8);
      padding: 5px 0;
      margin: 0;
      text-indent: 10px;
      -webkit-box-shadow: 0px -13px 9px 17px rgba(0,0,0,0.3);
      -moz-box-shadow: 0px -13px 9px 17px rgba(0,0,0,0.3);
      box-shadow: 0px -13px 9px 17px rgba(0,0,0,0.3);
      position: relative;
      z-index: 10;
      text-indent: 15px !important;
    }

    .section-wrap {
      background: rgba(0, 0, 0, .1);
      border-bottom: 1px rgba(0, 0, 0, .1) solid;
      padding: 15px;
      overflow: hidden !important;
      position: relative;
      z-index: 8;


      p {
        color: rgba(0, 0, 0, .5);
        margin: 0 !important;
        padding: 0 0 5px !important;
      }

      textarea {
        border-color: rgba(0, 0, 0, .2);
        border-radius: 5px !important;
      }

      &.first {
        .ant-col:last-child {
          text-align: right !important;

          .ant-radio-wrapper {
            margin-left: 10px;
            margin-right: 0;
            text-align: right;

            span {
              padding-right: 0;
            }
          }
        }

        p {
          padding: 0 !important;
        }
      }

      &.last {
        background: rgba(0, 0, 0, .5);
        padding: 10px 15px;
        text-align: right;
      }
    }

    .section-first-dropdown {
      background: #888;
      padding: 10px;
      overflow: hidden;
      animation-fill-mode: forwards;
      animation-duration: 0.5s;
      animation-name: dropdownWrapperAppears;

      &.visible-false {
        display: none;
      }

      @keyframes dropdownWrapperAppears {
        from {
          opacity: 0;
          height: 0 !important;
        }

        to {
          opacity: 1;
          heigh: 400px;
        }
      }

      .section-first-dropdown-inner {
        animation-duration: 1s;
        animation-fill-mode: forwards;
        animation-name: dropdownAppears;
        padding: 0 5px 10px;
        position: relative;

        @keyframes dropdownAppears {
          from {
            top: -1000px;
          }

          to {
            top: 0;
          }
        }

        p {
          color: rgba(255, 255, 255, .5);
          margin: 0;
          padding: 0 0 5px;
        }

        .ant-row {
          margin-bottom: 10px;

          &:last-child {
            margin-bottom: 0;
          }

          .ant-col {
            padding-right: 15px;

            .col-inner {
            }

            &:last-child {
              padding-right: 0;
            }
          }
        }
      }
    }
  }

  .foot {
    p {
      animation-duration: 0.5s;
      animation-fill-mode: forwards;
      animation-name: textAppears;

      @keyframes textAppears {
        from {
          transform: translateY(1000px);
        }
        to {
          transform: translateX(0);
        }
      }
    }
  }
}
