.modal-alert-card{
    .suspicious-operation-section{
        padding: 10px;

        .suspicious-operation-body{
            padding: 20px;

            .suspicious-operation-content-wrapper{
                border: 1px solid #d9d9d9;
                border-radius: 5px;
                padding: 40px;
            }
        }
    }
}