.tab-alerts {
  position: relative;
	margin-right: 10px;

  .ant-table-row {
    height: 50px;
  }

  .shit-x {
    border: 20px #00f solid;
  }

  .circle {
    border-radius: 25px;
    height: 50px;
    line-height: 50px;
    margin: 0 auto;
    text-align: center;
    width: 50px;
  }

  .has-positives {
    font-weight: bold;
    font-size: larger;
  }

  .risk-text {
    p, strong {
      margin: 0;
      padding: 0;
      font-weight: normal !important;
    }
  }

  & > .top-bar {
    .add-button {
      position: relative;
      top: 10px;
    }

    .btn-report {
      left: 10px;
      top: 10px;
      position: absolute;
    }

    .btn-reminders {
      left: 550px;
      top: 10px;
      position: absolute;
    }

    .btn-add-fake {
      left: 200px;
      top: 10px;
      position: absolute;
    }

    .btn-pop-fake {
      left: 300px;
      top: 10px;
      position: absolute;
    }

  }

  .ant-modal-close {
    z-index: -1 !important;
  }
  .spinner {
    box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.1) inset;
    -webkit-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.1) inset;
    -moz-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.1) inset;
    // min-height: 785px;
    min-height: 650px;
    margin-top: 0;
    position: relative;
    width: 100%;

    .ant-spin {
      left: 50%;
      margin: -10px 0 0 -10px;
      position: absolute;
      top: 200px;
    }
  }

  .top-bar {
    height: 32px;
    margin-bottom: 5px;
		position: relative;

    .advanced-search {
      background-color: transparent;
      border-bottom: 1px;
      border-bottom-style: solid;
      border-bottom-color: transparent;
      border-radius: 5px;
      color: rgba(0, 0, 0, .5);
      cursor: pointer;
      padding: 5px 10px 5px 0;
      position: absolute;
      right: 0;

      .anticon:first-child {
        font-size: 0.8em;
        margin-left: 10px;
        margin-top: -3px;
      }

      .anticon:last-child {
        font-size: 0.85em;
      }

      &.on {
        animation-duration: 0.5s;
        animation-fill-mode: forwards;
        animation-name: filters-are-open;

        @keyframes filters-are-open {
          to {
            background-color: rgba(0, 0, 0, .075);
            border-bottom-color: rgba(0, 0, 0, .05);
          }
        }

        &:hover {
          background-color: rgba(0, 0, 0, .075);
          border-bottom: 1px rgba(0, 0, 0, .05) solid;
        }
      }

      &:hover {
        background-color: rgba(0, 0, 0, .075);
        border-bottom: 1px rgba(0, 0, 0, .05) solid;
      }
    }
  }

  thead {
		color: black;
		background-color: rgb(255, 255, 255);

		th {
			border-radius: 0 !important;
			padding: 12px 4px 12px 8px !important;
		}
	}

	.ant-table-thead > tr >th {
		border-spacing: 0 !important;
		margin-left:1px;
		margin-bottom:1px;
		border-right: 1px solid rgba(0,0,0,.1);
		border-collapse: unset !important;
		background-color: #fff;
	}

  .bottom-bar {
    background: rgba(0, 0, 0, .3);
    bottom: 0;
    box-shadow: inset 0px 10px 18px -10px rgba(0, 0, 0, 0.25);
    height: 55px;
    padding-top: 12px;
    // position: absolute;
    position: relative;
    text-align: center;
    width: 100%;
    -webkit-box-shadow: inset 0px 10px 18px -10px rgba(0, 0, 0, 0.25);
    -moz-box-shadow: inset 0px 10px 18px -10px rgba(0, 0, 0, 0.25);
  }

  .ant-pagination {
    bottom: 10px;
    position: absolute;
    text-align: center;
    width: 100%;
    z-index: 2;
  }

  .ant-table-row {

    td {
    }
  }

  .filters-wrapper {
    overflow: hidden;
    position: relative;

    &.null {
      height: 0;

      .filters-wrapper-inner {
        transform: translateY(-200px);
      }
    }

    &.show {
      animation-duration: 0.75s;
      animation-fill-mode: forwards;
      animation-name: show-parent-tab-alert;
      z-index: 800;

      @keyframes show-parent-tab-alert {
        from {
          height: 0;
        }

        to {
          height: 95px;
        }
      }

      .filters-wrapper-inner {
        animation-duration: 0.75s;
        animation-fill-mode: forwards;
        animation-name: show-child;

        @keyframes show-child {
          from {
            transform: translateY(-200px);
          }

          to {
            transform: translateY(0);
          }
        }
      }
    }

    &.hide {
      animation-duration: 0.3s;
      animation-fill-mode: forwards;
      animation-name: hide-parent-tab-alert;
      overflow: hidden;
      position: relative;
      z-index: 800;

      @keyframes hide-parent-tab-alert {
        from {
          height: 110px;
        }

        to {
          height: 0;
        }
      }

      .filters-wrapper-inner {
        animation-duration: 0.3s;
        animation-fill-mode: forwards;
        animation-name: hide-child;

        @keyframes hide-child {
          from {
            transform: translateY(0);
          }

          to {
            transform: translateY(-200px);
          }
        }
      }
    }
  }

  h3 {
    font-size: 1em;
    color: rgba(0, 0, 0, .7);
  }

  .bottom-bar {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }
}


.new-alert-modal {
  min-height: 400px;
  position: relative;

  h3 {
    background: rgba(0, 0, 0, .5);
  }

  .shit-x {
    border: 20px #000 solid;
  }

  .box {
    background: rgba(0, 0, 0, .1);
    position: relative;
    animation-duration: 3s;
    animation-fill-mode: forwards;
    animation-name: content-fade-in;
    opacity: 0;

    @keyframes content-fade-in {
      to {
        opacity: 1;
      }
    }

    h2 {
      -webkit-box-shadow: inset 0px 6px 10px -6px rgba(0, 0, 0, 0.5);
      -moz-box-shadow: inset 0px 6px 10px -6px rgba(0, 0, 0, 0.5);
      box-shadow: inset 0px 6px 10px -6px rgba(0, 0, 0, 0.5);
      background: rgba(0, 0, 0, .4);
      color: white;
      font-size: 1.1em;
      height: 35px;
      line-height: 35px;
      margin-bottom: 0;
      text-indent: 10px;
    }

    &:first-child {
      h2 {
        box-shadow: none;
      }
    }

    .ant-table-body {
      margin: 0 !important;
      padding: 0;
    }

    .download-report {
      position: absolute;
      right: 10px;
      top: 5px;

      .ant-btn {
        font-size: 0.8em;

        a {
          color: white;
          text-decoration: none;
        }
      }
    }

    .box-inner {
      -webkit-box-shadow: inset 0px 6px 10px -6px rgba(0, 0, 0, 0.3);
      -moz-box-shadow: inset 0px 6px 10px -6px rgba(0, 0, 0, 0.3);
      box-shadow: inset 0px 6px 10px -6px rgba(0, 0, 0, 0.3);
      padding-bottom: 10px;

      .input-wrapper {
        padding-left: 5px;
        padding-right: 5px;

        span.label {
          display: block;
          padding-bottom: 0;
        }
      }

      .ant-table {
        .ant-table-thead > tr > th {
          background: rgba(0, 0, 0, .1);
          padding: 5px 0 0 10px;
        }
      }

      td {
        font-size: 0.8em;
      }

      & :first-child {
        padding-bottom: 0px;
      }

      & :last-child {
        padding-bottom: 0px;
      }

    }
  }
}

.alert-table {
  margin-bottom: 0px;
  // INICIO DE TRANSFERENCIA DE ESTILOS
  .number-register-wrapper {
    border-radius: 0.5rem;
    border: 1px solid #0000002d !important;
  }

  .name-col-table {
    button {
      text-align: left;
      padding: 0px;
      width: 100%;
      white-space: normal;
    }

    .ant-table-row .ant-table-row-level-0 {
      height: 55px;
    }
  }

  .pagination-formtable {
    margin-top: 20px;
  }

  .ant-table-row-cell-break-word {
    padding: 2px;
  }

  .solicitudes-card {
    background-color: #FAFFBD;
    border-radius: 4px;
    margin: 3px;
    text-align: center;
    vertical-align: middle;
  }

  .solicitudes-card-registros {
    background-color: #FAFFBD;
    border-radius: 4px;
    margin: 3px;
    text-align: center;
    vertical-align: middle;
  }


  .ellipsis-icon-onb {
    text-align: center;
  }


  .total-onb-card {
    background-color: #fff;
    border-radius: 4px;
    margin: 3px;
    text-align: center;
    vertical-align: middle;
  }

  .top-bar {
    margin-bottom: 5px;
    position: relative;

    .advanced-search {
      background-color: transparent;
      border-bottom: 1px;
      border-bottom-style: solid;
      border-bottom-color: transparent;
      border-radius: 5px;
      color: rgba(0, 0, 0, .5);
      cursor: pointer;
      padding: 5px 10px 5px 0;
      position: absolute;
      right: 14px;
      top: 14px;

      .anticon:first-child {
        font-size: 0.8em;
        margin-left: 10px;
        margin-top: -3px;
      }

      .anticon:last-child {
        font-size: 0.85em;
      }

      &.on {
        animation-duration: 0.5s;
        animation-fill-mode: forwards;
        animation-name: filters-are-open;

        @keyframes filters-are-open {
          to {
            background-color: rgba(0, 0, 0, .075);
            border-bottom-color: rgba(0, 0, 0, .05);
          }
        }

        &:hover {
          background-color: rgba(0, 0, 0, .075);
          border-bottom: 1px rgba(0, 0, 0, .05) solid;
        }
      }

      &:hover {
        background-color: rgba(0, 0, 0, .075);
        border-bottom: 1px rgba(0, 0, 0, .05) solid;
      }
    }
  }

  .ant-btn-link {
    font-size: 12px;
    height: 24px;
  }

  .ant-table-thead > tr > th.onbRiskTitle, .ant-table-thead > tr > th.onbLogoTitle {
    text-align: center;
    color: white;
    background: #196C93 !important;
  }


  .ant-table-thead > tr > th.onbLogoTitle {
    .image {
      width: 34px;
      height: 57px;
      // background-image: url(./logoimges.png);
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center center;
    }
  }

  .ant-table {

    .ant-table-body {
      margin-left: 0px !important;
      margin-right: 0px !important;
    }

    font-size: 12px;

    // .ant-table-header-column{
    // 	margin-left:-15px;
    // }
    .item-risk-align {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .child-risk-onb {
      border-radius: 8px;
      padding: 0px;
      text-align: center;
      vertical-align: middle;
      padding: 4px;
    }

    .child-risk-onb-circle {
      border-radius: 12px;
      height: 24px;
      width: 24px;
      line-height: 24px
    }


    .ant-table-column-sorter-inner {
      margin-top: -0.4em !important;
    }


    .edit-action {
      padding: 5px;
      width: 70px;

      &:hover {
        animation-duration: 0.5s;
        animation-fill-mode: forwards;
        animation-name: grey-fade-in;

        @keyframes grey-fade-in {
          to {
            background: rgba(0, 0, 0, .07);
            border-radius: 5px;
            cursor: pointer;
          }
        }
      }
    }

    .download-report {
      padding: 5px;
      width: 130px;

      &:hover {
        animation-duration: 0.5s;
        animation-fill-mode: forwards;
        animation-name: grey-fade-in;

        @keyframes grey-fade-in {
          to {
            background: rgba(0, 0, 0, .07);
            border-radius: 5px;
            cursor: pointer;
          }
        }
      }
    }
  }

  .ant-pagination {
    text-align: center;
    width: 100%;
  }

  .imgLogoGes {
    height: 100%;
    width: 100%;
  }

  .col-nombre-rut {
    Button {
      height: auto;
      text-align: left;
      font-size: 11px;
      padding: 0px;
    }
  }

  .col-category {
    padding-left: 10px;
  }

  .col-risk {
    text-align: center;
    font-size: 14px;


    .circle {
      font-weight: bold;
      color: white;
      padding-top: 4px;


      display: inline-block;
      font-size: 14px;
      height: 24px;
      line-height: 18px;
      text-align: center;
      width: 24px;
    }

    .circle-risk-0 {
      background: green;
    }

    .circle-risk-1 {
      background: yellow;
    }

    .circle-risk-2 {
      background: orange;
    }

    .circle-risk-3 {
      background: red;
    }

    .circle-risk-4 {
      background: black;
    }

    .quadrate {
      width: 14px;
      height: 14px;
      padding-left: 4px;
      padding-right: 4px;
      color: back;
      background: transparent !important;

    }


    .quadrate-risk-0 {
      border: 3px solid #598756;
    }

    .quadrate-risk-1 {
      border: 3px solid #fcdc6b;
    }

    .quadrate-risk-2 {
      border: 3px solid #FE9F0C;
    }

    .quadrate-risk-3 {
      border: 3px solid #d9534f;
    }

    .quadrate-risk-4 {
      border: 3px solid rgba(0, 0, 0, 0.7);
    }

  }


}
