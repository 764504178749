.admin-lists {
  .tools-area {
    border-radius: 5px;
    margin-bottom: 20px;
    padding: 20px;
    text-align: right;
  }

  .table-wrapper {
    min-height: 420px;
    position: relative;
  }

  .ant-pagination {
    text-align: center;
    width: 100%;
    z-index: 10;
  }
}
.riskSelector {
  width: 10px;
  height: 10px;
  text-align: right;
  display: flex;
}

.ant-select-selection-selected-value {
  width: 100%;
}
