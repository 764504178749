.report-history{
    .spin-loading-wrapper{
        display: flex;
        justify-content: center;
    }

    .ant-table{
        ::-webkit-scrollbar {
            display: none;
        }
    }
}