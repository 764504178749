.tab-alerts-simulator {
	margin-bottom: -20px;
	position: relative;


  .excel-download{
    position: absolute;
		bottom: 0px;
  }

  .shit-x {
    border: 20px #00f solid;
  }


  .header-description-row{
    padding: 10px 5px;
		min-height: 49px;
  }

  .save-button{
      &.ant-btn-sm{
        height: 17px;
        font-size: 10px;
      }
  }

  .header-description-col{
    font-size: 0.95em;
    margin-bottom: 0px;

  }

  .circle {
    border-radius: 25px;
    height: 50px;
    line-height: 50px;
    margin: 0 auto;
    text-align: center;
    width: 50px;
  }

  .has-positives {
    font-weight: bold;
		font-size: larger;
  }

  .risk-text {
    p, strong {
      margin: 0;
      padding: 0;
      font-weight: normal !important;
    }
  }

  & > .top-bar {

		min-height: 50px;
  }



	.spinner {
		box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.1) inset;
		-webkit-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.1) inset;
		-moz-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.1) inset;
		// min-height: 785px;
		min-height: 759px;
		margin-top: 0;
		position: relative;
		width: 100%;

		.ant-spin {
			left: 50%;
			margin: -10px 0 0 -10px;
			position: absolute;
			top: 200px;
		}
	}

	.top-bar {
    border-bottom: 1px rgba(0, 0, 0, .1) solid;
		position: relative;

		.advanced-search {
			background-color: transparent;
			border-bottom: 1px;
			border-bottom-style: solid;
			border-bottom-color: transparent;
			border-radius: 5px;
			color: rgba(0, 0, 0, .5);
			cursor: pointer;
			padding: 5px 10px 5px 0;
			position: absolute;
			right: 0;
			bottom: 0px;
			top: initial;

			.anticon:first-child {
				font-size: 0.8em;
				margin-left: 10px;
				margin-top: -3px;
			}

			.anticon:last-child {
				font-size: 0.85em;
			}

			&.on {
				animation-duration: 0.5s;
				animation-fill-mode: forwards;
				animation-name: filters-are-open;

				@keyframes filters-are-open {
					to {
						background-color: rgba(0, 0, 0, .075);
						border-bottom-color: rgba(0, 0, 0, .05);
					}
				}

				&:hover {
					background-color: rgba(0, 0, 0, .075);
					border-bottom: 1px rgba(0, 0, 0, .05) solid;
				}
			}

			&:hover {
				background-color: rgba(0, 0, 0, .075);
				border-bottom: 1px rgba(0, 0, 0, .05) solid;
			}
		}
	}

	thead {
		-webkit-box-shadow:0px 3px 6px 0px rgba(0, 0, 0, .1) inset;
		-moz-box-shadow:0px 3px 6px 0px rgba(0, 0, 0, .1) inset;
		box-shadow:0px 3px 6px 0px rgba(0, 0, 0, .1) inset;

		th {
			background: rgba(0, 0, 0, .3);
			border-radius: 0 !important;
			color: white;
			// padding: 10px 0 !important;
			padding: 10px 0;
			text-indent: 8px;
			&:last-child {
				padding-right: 22px;
			}
		}
	}

	.ant-table {
		font-size: 0.8em;
		color: rgba(0, 0, 0, .6);
		// min-height: 785px;
		min-height: 759px;

		.icon-wrapper {
			-webkit-box-shadow: 0px 4px 3px -1px rgba(0,0,0,0.14);
			-moz-box-shadow: 0px 4px 3px -1px rgba(0,0,0,0.14);
			box-shadow: 0px 4px 3px -1px rgba(0,0,0,0.14);
			background: rgba(0, 0, 0, .3);
			border-radius: 20px;
			height: 40px;
			line-height: 40px;
			width: 40px;

			img {
				display: block;
				height: 22px;
				left: 9px;
				position: relative;
				top: 9px;
			}
		}

    h3 {
      font-weight: normal;
      font-size: 0.9em;
    }

		.metrics-wrapper {
			width: 200px;

			ul {
				list-style-type: none;
				margin: 0;
				padding: 0;

				li {
					font-size: 0.9em;
					line-height: 12px;
					padding: 0 0 6px;

					.bar-wrapper {
						overflow: hidden;

						span {
							display: inline-block;
							float: left;
							width: 80px !important;
						}

						.bar {
							background: rgba(0, 0, 0, .1);
							border-radius: 6px;
							float: right;
							overflow: hidden;
							width: 100px !important;

							.bar-inner {
								background: rgba(0, 0, 0, .1);
								font-size: 1em;
								height: 12px;
								text-indent: 3px;
								width: 70px;
							}
						}
					}

					&:last-child {
						padding: 0;
					}
				}
			}
		}

		.edit-action {
			padding: 5px;
			width: 70px;

			&:hover {
				animation-duration: 0.5s;
				animation-fill-mode: forwards;
				animation-name: grey-fade-in;

				@keyframes grey-fade-in {
					to {
						background: rgba(0, 0, 0, .07);
						border-radius: 5px;
						cursor: pointer;
					}
				}
			}
		}

		.download-report {
			padding: 5px;

			&:hover {
				animation-duration: 0.5s;
				animation-fill-mode: forwards;
				animation-name: grey-fade-in;

				@keyframes grey-fade-in {
					to {
						background: rgba(0, 0, 0, .07);
						border-radius: 5px;
						cursor: pointer;
					}
				}
			}
		}

		table {
			border-radius: 0 !important;
		}

		tr.row-form {
			cursor: pointer;
		}
	}

	.bottom-bar {
		background: rgba(0, 0, 0, .3);
		bottom: 0;
		box-shadow: inset 0px 10px 18px -10px rgba(0, 0, 0, 0.25);
		height: 55px;
		padding-top: 12px;
		// position: absolute;
		position: relative;
		text-align: center;
		width: 100%;
		-webkit-box-shadow: inset 0px 10px 18px -10px rgba(0, 0, 0, 0.25);
		-moz-box-shadow: inset 0px 10px 18px -10px rgba(0, 0, 0, 0.25);
	}

	.ant-pagination {
		bottom: 10px;
		position: absolute;
		text-align: center;
		width: 100%;
		z-index: 2;
	}

	.ant-table-row {

		td {
		}
	}

	.filters-wrapper {
		overflow: hidden;
		position: relative;

		&.null {
			height: 0;

			.filters-wrapper-inner {
				transform: translateY(-200px);
			}
		}

		&.show {
			animation-duration: 0.75s;
			animation-fill-mode: forwards;
			animation-name: show-parent-tab-alert;
			z-index: 800;

			@keyframes show-parent-tab-alert {
				from {
					height: 0;
				}

				to {
					height: 95px;
				}
			}

			.filters-wrapper-inner {
				animation-duration: 0.75s;
				animation-fill-mode: forwards;
				animation-name: show-child;

				@keyframes show-child {
					from {
						transform: translateY(-200px);
					}

					to {
						transform: translateY(0);
					}
				}
			}
		}

		&.hide {
			animation-duration: 0.3s;
			animation-fill-mode: forwards;
			animation-name: hide-parent-tab-alert;
			overflow: hidden;
			position: relative;
			z-index: 800;

			@keyframes hide-parent-tab-alert {
				from {
					height: 110px;
				}

				to {
					height: 0;
				}
			}

			.filters-wrapper-inner {
				animation-duration: 0.3s;
				animation-fill-mode: forwards;
				animation-name: hide-child;

				@keyframes hide-child {
					from {
						transform: translateY(0);
					}

					to {
						transform: translateY(-200px);
					}
				}
			}
		}
	}

	h3 {
		font-size: 1em;
		color: rgba(0, 0, 0, .7);
	}

  .alerts-pseudo-table {
    border: 1px rgba(0, 0, 0, .15) solid;
    border-radius: 5px;
    min-height: 300px;
    margin-bottom: 20px;
    -webkit-box-shadow: 0px 0px 25px -3px rgba(0,0,0,0.15);
    -moz-box-shadow: 0px 0px 25px -3px rgba(0,0,0,0.15);
    box-shadow: 0px 0px 25px -3px rgba(0,0,0,0.15);

    .thead {
      .section {
        float: left;

        h4 {
          background: #666;
          -webkit-box-shadow: 0px 2px 20px -8px rgba(0,0,0,0.75);
          -moz-box-shadow: 0px 2px 20px -8px rgba(0,0,0,0.75);
          box-shadow: 0px 2px 20px -8px rgba(0,0,0,0.75);
          color: rgba(255, 255, 255, .5);
          height: 40px;
          line-height: 40px;
          margin: 0;
          text-align: center;
        }

        .th {
          display: flex;
          align-items: center;
          justify-content: left;

          p {
            color: rgba(0, 0, 0, .5) !important;
            display: block !important;
            font-size: 0.8em;
            line-height: 1.1em;
            margin-bottom: 10px;

            &:last-child {
              margin-bottom: 0;
            }
          }
        }

        &:first-child {
          border-right: 1px rgba(0, 0, 0, .05) solid;
          overflow: hidden;
          width: 100%;
          position: relative;
          z-index: 2;

          h4 {
            border-top-left-radius: 5px;
            position: relative;
            z-index: 2;
          }

          .th {
            background-color: rgba(0, 0, 0, .1);
            border-right: 1px rgba(0, 0, 0, .2) solid;
            height: 72px;
            float: left;
            padding: 0 10px;
            width: 33.333% !important;

            &:last-child {
              border-right: 0;
            }
          }
        }

        &:last-child {
          width: 100%;

          h4 {
            -webkit-box-shadow: inset 29px 0px 27px -22px rgba(0,0,0,0.02);
            -moz-box-shadow: inset 29px 0px 27px -22px rgba(0,0,0,0.02);
            box-shadow: inset 29px 0px 27px -22px rgba(0,0,0,0.02);
            background: #555;
            border-top-right-radius: 5px;
          }

          p {
            position: relative;
          }

          .th {
            background: rgba(0, 0, 0, .15);
            height: 72px;
            -webkit-box-shadow: inset 29px 0px 27px -22px rgba(0,0,0,0.08);
            -moz-box-shadow: inset 29px 0px 27px -22px rgba(0,0,0,0.08);
            box-shadow: inset 29px 0px 27px -22px rgba(0,0,0,0.08);
          }
        }
      }
    }

    .tbody {
      min-height: 500px;

      .alert-item {
        background-image: url(./shadow.png);
        background-repeat: repeat-y;
        background-position:  715px top;
        -webkit-box-shadow: inset 0px 5px 5px 0px rgba(0,0,0,0.015);
        -moz-box-shadow: inset 0px 5px 5px 0px rgba(0,0,0,0.015);
        box-shadow: inset 0px 5px 5px 0px rgba(0,0,0,0.015);

        border-bottom: 1px rgba(0, 0, 0, .1) solid;
        clear: left;
        overflow: hidden;

        .alert-title {
          background: rgba(0, 0, 0, .35);
          border-radius: 5px;
          color: rgba(255, 255, 255, .7);
          margin: 10px 10px 0;
          padding: 5px 0;
          text-align: center;
        }

        .ant-col {
          padding: 10px;
          margin: 0;
          width: 33.333%;

          .col-inner {
            p {
              color: rgba(0, 0, 0, .5);
              font-size: 0.9em;
              margin-bottom: 5px;

              &:last-child {
                margin-bottom: 0;
              }

              .circle {
                background: rgba(0, 0, 0, .5);
                color: rgba(255, 255, 255, .6);
                display: inline-block;
                font-size: 0.8em;
                font-weight: bold;
                line-height: 20px;
                height: 20px;
                text-align: center;
                width: 20px;

                &:last-child {
                  background: green;
                }
              }
            }
          }
        }

        &:nth-child(even) {
          background-color: rgba(255, 255, 255, .25);
        }

        &:hover {
          background-color: #fff;
          background-image: none;
          cursor: pointer;
          animation-name: hoverAnim;
          animation-duration: 0.1s;
          animation-fill-mode: forwards;
          border-radius: 10px;

          @keyframes hoverAnim {
            to {
              background-color: #fff;
              transform: scaleX(3) !important;
            }
          }
        }
      }
    }
    }

    .bottom-bar {
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
    }
}

.new-alert-modal {
  min-height: 400px;
  position: relative;

  h3 {
    background: rgba(0, 0, 0, .5);
  }

  .shit-x {
    border: 20px #000 solid;
  }

  .box {
    background: rgba(0, 0, 0, .1);
    position: relative;
    animation-duration: 3s;
    animation-fill-mode: forwards;
    animation-name: content-fade-in;
    opacity: 0;

    @keyframes content-fade-in {
      to {
        opacity: 1;
      }
    }

    h2 {
      -webkit-box-shadow: inset 0px 6px 10px -6px rgba(0,0,0,0.5);
      -moz-box-shadow: inset 0px 6px 10px -6px rgba(0,0,0,0.5);
      box-shadow: inset 0px 6px 10px -6px rgba(0,0,0,0.5);
      background: rgba(0, 0, 0, .4);
      color: white;
      font-size: 1.1em;
      height: 35px;
      line-height: 35px;
      margin-bottom: 0;
      text-indent: 10px;
    }

    &:first-child {
      h2 {
        box-shadow: none;
      }
    }

    .ant-table-body {
      margin: 0 !important;
      padding: 0;
    }

    .download-report {
      position: absolute;
      right: 10px;
      top: 5px;

      .ant-btn {
        font-size: 0.8em;

        a {
          color: white;
          text-decoration: none;
        }
      }
    }

    .box-inner {
      -webkit-box-shadow: inset 0px 6px 10px -6px rgba(0,0,0,0.3);
      -moz-box-shadow: inset 0px 6px 10px -6px rgba(0,0,0,0.3);
      box-shadow: inset 0px 6px 10px -6px rgba(0,0,0,0.3);
      padding-bottom: 10px;

      .input-wrapper {
        padding-left: 5px;
        padding-right: 5px;

        span.label {
          display: block;
          padding-bottom: 0;
        }
      }

      .ant-table {
        .ant-table-thead > tr > th {
          background: rgba(0, 0, 0, .1);
          padding: 5px 0 0 10px;
        }
      }

      td{
        font-size: 0.8em;
      }

      & :first-child{
        padding-bottom: 0px;
      }

      & :last-child{
        padding-bottom: 0px;
      }
    }
  }
}

#overlay-alert-detail-simulator {
	display: block;
	position: absolute;
	left: 0px;
	top:0px;
	width: 100%;
	min-height: 671px;
	background-color: #fff;
	z-index: 1000;

	#content {
		margin: 0px;
	}
}
