.current-user {
  border-left: 1px rgba(0, 0, 0, .1) solid;
  color: rgba(255, 255, 255, 0.65);
  //float: right;
  width: 167px;
  line-height: 50px;
  display: block;

  .ant-btn {
    background-color: transparent;
    border: 0;
    color: rgba(0, 0, 0, .6);
    font-size: 0.9em;
    height: 44px;
    padding: 0 20px;
  }

  .ant-dropdown {
    font-size: 0.9em !important;
    width: 500px !important;
  }
}

.menu-user{
 .ant-dropdown-menu {
    background-color: rgba(255, 255, 255, .7) ;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
 }
}


