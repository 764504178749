
.content-dashboard {

  .level-indicator {

    background: #2d0370;
    margin-top: 13px;
    margin-bottom: 13px;

    .text-indicator {
      padding: 7px;
      font-size: 17px;
      color: white;
    }
  }

  .label-no-info{
    height: 69px;
    font-weight: 500;
    padding-top: 20px;
  }

  .spinner {
    left: 50%;
    top: 50%;
  }
}