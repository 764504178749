@keyframes sidebarMenuAnimationRightOpen {
    0% {right: -300px;}
    100% {right: 0px;}
}

@keyframes sidebarMenuAnimationRightClosed {
    0% {right: 0px;}
    100% {right: -300px;}
}

.sidebar-search-history {
    position: absolute;
    padding-left: 3px;
    border-radius: 2px;
    top: 0px;
    height: 100%;
    width: 300px;
    background-color: rgba(255, 255, 255, 0.4);
    min-height: 860px;
    overflow-y: auto;

    .ant-menu {
        height: 100%;
        background-color: rgba(255, 255, 255, 0.4);
    }

    &.collapsed-true {
      animation-name: sidebarMenuAnimationRightOpen;
      animation-duration: 1s;
    }

    &.collapsed-false {
      animation-name: sidebarMenuAnimationRightClosed;
      animation-duration: 1s;
    }

    .ant-table {
        min-height: 690px;

        thead {
            background-color: rgba(0,0,0,.35);
            color: white;
            .ant-table-column-title {
                color: white;
            }
        }
        tbody {
            tr {
                cursor: pointer;

                td.fecha {
                    cursor: initial;
                    text-align: center;
                    font-weight: bold;
                }
                td.fechaName {
                    display: none;
                }
            }
            tr:nth-child(even){ 
                background: #fbfbfb;
            }
        }
    }
    .ant-pagination {
        position: relative;
        text-align: center;
        float: unset;
    }
}