.profile {
  .content {
    background-color: rgba(255, 255, 255, .5);
    min-height: 798px !important;
    position: relative !important;

    .ant-spin {
      left: 50% !important;
      margin-top: -30px !important;
      position: absolute !important;
      top: 50% !important;
    }

    .ant-tree-title {
      text-transform: capitalize;
    }
  }
}
