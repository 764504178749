
.alerts-generated-content {

  .row-principal-content {
    padding-top: 8px;

    .alert-generated-name {

      text-align: left;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;

      .asigned-name {
        font-size: 14px;
        color: #06068a;
      }
    }


    .full-advance {
      width: 100%;
      background-color: coral;
      border-color: coral;
      font-size: 14px;
      height: 20px;

      .total-alerts {
        font-weight: bolder;
      }

      .level-advance-indicator {
        background: #5c96d3;
        color: white;
        height: 100%;
        cursor: pointer;
        z-index: 10;
        position: relative;
      }

      .level-closed-alerts {
        float: left;
        padding-left: 5px ;
        color: white;
      }

      .level-open-alerts {
        position: absolute;
        width: 100%;
        text-align: right;
        padding-right: 5px;
        color: white;
        cursor: pointer;
      }

    }
  }

  .row-percent {
    padding-top: 10px;

    .col-space {
      text-align: center;
      font-size: 14px;
      height: 40px;
    }
  }

  .row-info-color-label {

    .label-legend {
      position: relative;
      float: left;
      padding: 4%;
      margin-top: 2%;
    }

    .open {
      background: coral;
    }

    .closed {
      background: #5c96d3;
    }

    .label-content {
      float:left;
    }
  }
}