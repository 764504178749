.admin-risk {
  .tools-area {
    border-radius: 5px;
    margin-bottom: 20px;
    padding: 20px;
    text-align: right;
  }
  .riesgo-cell {
    text-align: center;
    border-right: 1px solid #e8e8e8;
    border-bottom: 1px solid #e8e8e8;
  }
  .riesgo-cell-title {
    font-weight: bold;
  }
  .riesgo-cell-column {
    border-right: 1px solid #e8e8e8;
    border-bottom: 1px solid #e8e8e8;
    border-left: 1px solid #e8e8e8;
    background-color: #fafafa;
    height: 37px;
  }

  h3.title-section {
    background-color: rgba(0, 0, 0, 0.3);
    margin: 10px 0px 0px 0px;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    color: rgba(255, 255, 255, 1);
    font-weight: bold;
    padding: 7px 0;
    text-align: center;
  }

  .cell-title {
    background-color: #fafafa;
    padding: 8px;
    border-right: 1px solid #e8e8e8;
    border-bottom: 1px solid #e8e8e8;
    border-left: 1px solid #e8e8e8;
  }
  .cell-title-section {
    font-weight: bold;
  }
  .cell-section-br {
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  }
  .riesgo-cell-title, .riesgo-cell {
    text-align: center;
  }
  .risk-GREEN, .risk-ORANGE, .risk-YELLOW, .risk-RED, .risk-BLACK {
    margin: 1px;
    padding: 7px;
  }
  div.ant-radio-group {
    width: 100%;
  }
  label.ant-radio-wrapper {
    width: 20%;
    border-right: 1px solid #e8e8e8;
    padding: 8px;
    margin: 0px;
  }
  .fields-required {
    background-color: rgba(255, 0, 0, 0.1);
  }
}
