.modal-alert-card{
    .alert-assignment-section{
        padding: 10px;

        .alert-assignment-body{
            padding: 20px;
            .alert-assignment-content-wrapper{
                border: 1px solid #d9d9d9;
                border-radius: 5px;
                padding: 25px;
            }
        }
    }
}