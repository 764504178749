  .add-text-file-button {
    animation-duration: 1s;
    animation-fill-mode: forwards;
    animation-name: add-button-fade-in;
    background: rgba(0, 0, 0, .5);
    border-radius: 10px;
    cursor: pointer;
    align-items: center;
    display: flex;
    justify-content: center;
    float: left;
    height: 210px;
    line-height: 200px;
    margin-right: 20px;
    text-align: center;
    width: 170px;

    @keyframes add-button-fade-in {
      from {
        opacity: 0;
      }

      to {
        opacity: 1;
      }
    }

    .anticon {
      color: white;
      font-size: 5em;
      position: relative;
    }
  }
