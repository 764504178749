.billingMessage {
  padding: 20px 15% !important;
  background-color: #9e9c9c;
  border: 2px solid #ccc;
  width: 100%;
  .ant-alert-message {
    font-size: 17px;
    color: #dddddd;
    font-weight: 500;
  }
}
