#consulta {

  .ant-form-item {
    float: left;
    margin-bottom: 0;
  }
  
  #content{
    width: 100%;
    padding: 5px;
  }

  /* Style the links inside the sidenav */
  #mySidenav div {
  position: absolute; /* Position them relative to the browser window */
  left: -18px; /* Position them outside of the screen */
  transition: 0.3s; /* Add transition on hover */
  width: 1000px; /* Set a specific width */
  text-decoration: none; /* Remove underline */
  border: 1px solid black;

}

  #mySidenav div:hover {
    left: 0; /* On mouse-over, make the elements appear as they should */
  }

  /* The about link: 20px from the top with a green background */
  .menu-wrapper {

  }

  .page {
    position: relative;
    
    &#real-person-page {
      z-index: 1000;
    }

    .page-toolbar {
      position: relative;

      &.is-advanced-search {
        animation-duration: 0.3s;
        animation-fill-mode: forwards;
        animation-name: show-advanced-search-filters
      }
    }

    .page-content {
      overflow: visible;

      &.empty {
        background: rgba(255, 255, 255, .5) url('./bg.png') no-repeat center center;
      }

      &.grow-15 {
        height: 2245px;
        transition: height 0.8s ease-out;
      }

      &.grow-10 {
        height: 1545px;
        transition: height 0.8s ease-out;
      }

      &.grow-5 {
        height: 845px;
        transition: height 0.8s ease-out;
      }

      &.shrink {
        height: 550px;
        transition: height 1s ease-out;
      }

      .page-content-inner {
        &.fade-out {
          opacity: 0;
          transition: opacity 0.8s ease-out;
        }

        .table-headers {
          background: #a1aab7;
          border-radius: 5px;
          color: #fff;
          margin-bottom: 15px;
          padding-right: 35px;

          .ant-row {
            border-bottom-left-radius: 5px;
            border-top-left-radius: 5px;
            overflow: hidden;

            .ant-col {
              line-height: 40px;
              height: 40px;
              text-align: center;

              &:nth-child(even) {
                background: #a1aab7;
              }

              &:nth-child(odd) {
                background: #8b98a8;
              }

              &:last-child {
              }
            }
          }
        }

        .zero-results {
          height: 550px;

          center {
            font-size: 1.5em;
          }
        }
      }
    }

    .page-footer {
      bottom: 0;
      height: 70px;
      position: footer;
      width: 100%;
    }
  }

  .ant-spin {
    font-size: 2em;
    left: 50%;
    margin-left: -16px;
    margin-top: -16px;
    position: absolute;
    top: 27%;
  }
}

.leaves-screen-to-left {
  animation-duration: 0.3s !important;
  animation-fill-mode: forwards !important;
  animation-name: page-leaves-screen-to-left !important;
  position: absolute;
  -webkit-animation-duration: 0.3s !important;
  -webkit-animation-fill-mode: forwards !important;
  -webkit-animation-name: page-leaves-screen-to-left !important;
}

@keyframes page-content-grow-15 {
  from {
    height: 550px;
  }

  to {
    height: 2090px;
  }
}

@keyframes page-content-grow-10 {
  from {
    height: 550px;
  }

  to {
    height: 1390px !important;
  }
}

@keyframes page-content-grow-5 {
  from {
    height: 550px;
  }

  to {
    height: 690px;
  }
}

@keyframes page-content-shrink {
  to {
    height: 550px;
  }
}

@keyframes page-leaves-screen-to-left {
  from {
    left: 0;
    opacity: 1;
    top: 0;
  }

  to {
    opacity: 0;
    left: -100%;
    top: 0;
  }
}

@keyframes transition-profile-page-enters {
  from {
    left: 100%;
    opacity: 0;
  }

  to {
    left: 50%;
    margin-left: -600px;
    opacity: 1;
  }
}

@keyframes show-advanced-search-filters {
  from {
    height: 73px;
  }

  to {
    height: 167px;
  }
}

.btn-collapsed-menu-wrapper{
  position: absolute;
  right: 0px;
  top: 0px;

  .ant-btn{
    left: 14px;
    bottom: 18px;
  }
}