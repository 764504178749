#header {
  animation-name: header-slide-down;
  animation-duration: 1s;
  //background: #293443;
  background: rgba(255, 255, 255, .5);
  height: 50px;
  left: 0;
  margin-bottom: 10px;
  overflow: hidden;
  padding: 0;
  right: 0;
  top: 0;
  z-index: 1;
  -webkit-box-shadow: 0px 3px 5px -1px rgba(0,0,0,0.08);
  -moz-box-shadow: 0px 3px 5px -1px rgba(0,0,0,0.08);
  box-shadow: 0px 3px 5px -1px rgba(0,0,0,0.08);
  //mix-blend-mode: color-dodge;

  &.pdf {
      height: 0px;
  }

  &.logout-animation {
    animation-duration: 0.5s;
    animation-name: header-slide-up;
  }

  .ant-menu-horizontal {
    line-height: 55px;
    border-bottom: 0;
    line-height: 50px !important;
    font-size: 0.9em;
  }

  .ant-menu-horizontal > .ant-menu-item, .ant-menu-horizontal > .ant-menu-submenu {
    border-bottom: 0;
    top: 0;
  }

  .ant-menu-horizontal > .ant-menu-item:hover, .ant-menu-horizontal > .ant-menu-submenu:hover, .ant-menu-horizontal > .ant-menu-item-active, .ant-menu-horizontal > .ant-menu-submenu-active, .ant-menu-horizontal > .ant-menu-item-open, .ant-menu-horizontal > .ant-menu-submenu-open, .ant-menu-horizontal > .ant-menu-item-selected, .ant-menu-horizontal > .ant-menu-submenu-selected {
    border-bottom: 0;
  }
}


@keyframes header-slide-up {
  from {
    top: 0;
  }

  to {
    top: -64px;
  }
}


@keyframes header-slide-down {
  from {
    top: -64px;
  }

  to {
    top: 0;
  }
}
