.conflicts-filters {
	background-color: rgba(0, 0, 0, .1) !important;
	box-shadow: 0px 8px 17px 0px rgba(0,0,0,0.06);
	position: relative;
  margin-bottom: 20px;
  margin-right: 20px;
	-webkit-box-shadow: 0px 8px 17px 0px rgba(0,0,0,0.06);
	-moz-box-shadow: 0px 8px 17px 0px rgba(0,0,0,0.06);

	.filters-inner {
		box-shadow: inset 0px 10px 18px -10px rgba(0,0,0,0.25);
		padding: 10px 15px 7px;
		-webkit-box-shadow: inset 0px 10px 18px -10px rgba(0,0,0,0.25);
		-moz-box-shadow: inset 0px 10px 18px -10px rgba(0,0,0,0.25);
	}

	.ant-form-item {
		margin-bottom: 0;
	}

  .ant-input-suffix {
    display: none;
  }

	.ant-row {
		&:nth-child(2) {
			margin-bottom: 5px;
		}

		.ant-col {
			.col-inner {
				padding: 0 5px 0 5px;

				label {
					color: rgba(255, 255, 255, .7);
					display: block;
					line-height: 1em;
					margin-bottom: 0;
				}
			}

			&:first-child {
				.col-inner {
					padding-left: 0;
				}
			}

			&:last-child {
				.col-inner {
					padding-right: 0;
				}
			}
		}
	}

	.buttons {
		box-shadow: inset 0px 10px 18px -10px rgba(0,0,0,0.25);
		padding: 0 0 10px 0;
		text-align: center;
		-webkit-box-shadow: inset 0px 10px 18px -10px rgba(0,0,0,0.25);
		-moz-box-shadow: inset 0px 10px 18px -10px rgba(0,0,0,0.25);

    .ant-row {
      margin: 0 auto;
      width: 500px;
    }

		.ant-btn {
			background-color: rgba(255, 255, 255, .1) !important;
      border: 0;
      border-top: 1px rgba(0, 0, 0, .1) solid !important;
      border-radius: 0;
      font-size: 0.9em;
			height: 27px;
			margin: 7px 0 0;
			text-shadow: 0px 1px 3px rgba(0,1,0,0.1);
			width: 250px;

      &:first-child {
        border-bottom-left-radius: 3px !important;
        border-top-left-radius: 3px !important;
        border-right: 1px rgba(0, 0, 0, .3) solid !important;
      }

      &:last-child {
        border-top-right-radius: 3px !important;
        border-bottom-right-radius: 3px !important;
      }

			&:hover {
				background-color: rgba(255, 255, 255, .2) !important;
			}
		}
	}
}
