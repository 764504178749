
.risk-resume-content {
  margin-top: 5px;

  .title-risk {
    color: #06068a;
    font-weight: bold;
    font-size: 16px;
    text-align: center;
    padding-top: 15px;
  }

  .wrapper-risk {
    text-align: center;

    .circle-risk {
      margin: auto;
      width: 110px;
      height: 110px;
      border-radius: 49%;

      .risk-value {
        color: white;
        line-height: 110px;
        text-align: center;
        font-size: 36px;
      }
    }

    .risk-1 {
      background: yellow;
    }

    .risk-2 {
      background: orange;
    }

    .risk-3 {
      background: red;
    }

    .risk-4 {
      background: black;
    }

  }

  .risk-filter {

    position: relative;

    .ant-col {
      padding-right: 53px;

    }
  }
}