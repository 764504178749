.login {
  background-color: transparent;
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;

  #pass-instructions {
    border-top: 1px rgba(0, 0, 0, .1) solid !important;
    padding-top: 10px;
    margin: 10px 0;
  }

  .login-header {
    .ant-col {
      height: 150px;

      .spin-wrapper {
        height: inherit;
        position: relative;

        .ant-spin {
          left: 50%;
          margin-left: -16px;
          margin-top: -16px;
          position: absolute;
          top: 50%;
        }
      }
    }

    .ant-spin-dot-item {
      background-color: #fff;
    }
  }

  .login-content {
    .login-box {
      min-width: 380px;
      animation-duration: 1s;
      animation-name: login-fade-in;
      background: rgba(255, 255, 255, .3);
      border-radius: 5px;
      padding: 20px 30px;
      text-align: center;
      -webkit-box-shadow: 0px 0px 15px 1px rgba(0,0,0,0.1);
      -moz-box-shadow: 0px 0px 15px 1px rgba(0,0,0,0.1);
      box-shadow: 0px 0px 15px 1px rgba(0,0,0,0.1);

      h2 {
        color: rgba(0, 0, 0, .6);
        font-size: 1.4m;
        margin: 0 0 7px;
      }

      .ant-form-item {
        margin-bottom: 0;

        .ant-form-item-label {
          line-height: 2em;
        }
      }

      .login-form-button {
        margin: 5px auto 0;
      }
      .login-form-button-portal {
        margin: 28px auto 0;
        width: 100%;
        background-color: #5b9bd5 !important;
      }
     }
  }

  .forgot-pwd {
    padding-top: 15px;
  }
}

@keyframes login-fade-in {
  from {
    margin-top: -10px;
    opacity: 0;
  }

  to {
    margin-top: 0;
    opacity: 1;
  }
}
