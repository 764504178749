.modal-fichaCliente-register .monitoreo-quiebras {

  .modal-risk-main-wrapper{
    padding: 20px;
  }

  .risk-wrapper{
    border-radius: 20px;
    width: 20px;
    height: 20px;
  }

  .risk-type-wrapper-quiebras{
    border: 1px solid #000;
  }
}
  
  