.inside-dashboard{
  padding: 10px;
  .inside-topbar-content{
    padding: 20px;
    .ant-radio-button-wrapper-checked{
      background: rgba(0, 0, 0, 0.7) !important;
      color: #fff !important;
      box-shadow: 0px 0 0 0 #ff1818;
    }
  }

  .text-no-content{
    text-align: center;
    font-weight: 600;
    font-size: 16px;
    margin-top: 120px;
    margin-bottom: 100px;
  }

  .text-no-content-rule{
    text-align: center;
    font-weight: 600;
    font-size: 16px;
    margin-top: 120px;
  }

  .big-number-inside {
    text-align: center;
    font-size: 27px;
    font-weight: 900;
    padding-top: 10px;
  }

  .small-text-relations-cdi{
    text-align: center;
    font-weight: 600;
    font-size: 16px;
    white-space: nowrap;
  }

  .progress-container-inside-compliance-4{
    .ant-progress-success-bg{
      background-color: black;
    }
  }
  .progress-container-inside-compliance-3{
    .ant-progress-success-bg{
      background-color: #ee220c;
    }
  }
  .progress-container-inside-compliance-2{
    .ant-progress-success-bg{
      background-color: #ff9300;
    }
  }
  .progress-container-inside-compliance-1{
    .ant-progress-success-bg{
      background-color: #fae232;
    }
  }
}