.tab-rules {
	position: relative;
	margin-right: 10px;

	$color-risk-HIGH:rgb(217, 83, 79);
	$color-risk-MEDIUM:rgb(255, 196, 51);
	$color-risk-LOW:rgb(252, 220, 107);

	@mixin circle {
		border-radius: 25px;
		height: 50px;
		line-height: 50px;
		margin: 0 auto;
		text-align: center;
		width: 50px;
	}

	.circle-HIGH {
		@include circle;
		background-color:$color-risk-HIGH;
	}

	.circle-MEDIUM {
		@include circle;
		background-color:$color-risk-MEDIUM;
	}

	.circle-LOW {
		@include circle;
		background-color:$color-risk-LOW;
	}

  .has-positives {
    font-weight: bold;
		font-size: larger;
  }

  .risk-text {
    p, strong {
      margin: 0;
      padding: 0;
      font-weight: normal !important;
    }
  }

	.ant-pagination {
			float: none;
			text-align: center;
	}

  & > .top-bar {

	.btn-products-score {
      left: 200px;
      top: 10px;
      position: absolute;
	}

    .btn-report {
      left: 10px;
      top: 10px;
      position: absolute;
		}

    .btn-reminders {
      left: 550px;
      top: 10px;
      position: absolute;
		}

		.btn-add-fake {
      left: 400px;
      top: 10px;
      position: absolute;
		}

		.btn-pop-fake {
      left: 500px;
      top: 10px;
      position: absolute;
		}

  }



	.spinner {
		box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.1) inset;
		-webkit-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.1) inset;
		-moz-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.1) inset;
		// min-height: 785px;
		min-height: 759px;
		margin-top: 0;
		position: relative;
		width: 100%;

		.ant-spin {
			left: 50%;
			margin: -10px 0 0 -10px;
			position: absolute;
			top: 200px;
		}
	}

	.top-bar {
		margin-bottom: 5px;
		position: relative;
		height: 32px;

		.advanced-search {
			background-color: transparent;
			border-bottom: 1px;
			border-bottom-style: solid;
			border-bottom-color: transparent;
			border-radius: 5px;
			color: rgba(0, 0, 0, .5);
			cursor: pointer;
			padding: 5px 10px 5px 0;
			position: absolute;
			right: 0px;
			.anticon:first-child {
				font-size: 0.8em;
				margin-left: 10px;
				margin-top: -3px;
			}

			.anticon:last-child {
				font-size: 0.85em;
			}

			&.on {
				animation-duration: 0.5s;
				animation-fill-mode: forwards;
				animation-name: filters-are-open;

				@keyframes filters-are-open {
					to {
						background-color: rgba(0, 0, 0, .075);
						border-bottom-color: rgba(0, 0, 0, .05);
					}
				}

				&:hover {
					background-color: rgba(0, 0, 0, .075);
					border-bottom: 1px rgba(0, 0, 0, .05) solid;
				}
			}

			&:hover {
				background-color: rgba(0, 0, 0, .075);
				border-bottom: 1px rgba(0, 0, 0, .05) solid;
			}
		}
	}

	thead {
		-webkit-box-shadow:0px 3px 6px 0px rgba(0, 0, 0, .1) inset;
		-moz-box-shadow:0px 3px 6px 0px rgba(0, 0, 0, .1) inset;
		box-shadow:0px 3px 6px 0px rgba(0, 0, 0, .1) inset;

		th {
			border-radius: 0 !important;
			// padding: 10px 0;
			// text-indent: 8px;
			&:last-child {
				padding-right: 22px;
			}
		}
	}

	.row-form{
		cursor: pointer;
	}
	.ant-table {

		.ant-table-body {
			margin-left: 0px !important;
			margin-right: 0px !important;

			.anticon-file-pdf {
				color: rgba(0,0,250,.7);
			}
		}

		font-size: 12px;
		color: rgba(0, 0, 0, .6);

		.ant-table-column-sorter-inner {
			margin-top: -0.4em !important;
		}

		.icon-wrapper {
			-webkit-box-shadow: 0px 4px 3px -1px rgba(0,0,0,0.14);
			-moz-box-shadow: 0px 4px 3px -1px rgba(0,0,0,0.14);
			box-shadow: 0px 4px 3px -1px rgba(0,0,0,0.14);
			background: rgba(0, 0, 0, .3);
			border-radius: 20px;
			height: 40px;
			line-height: 40px;
			width: 40px;

			img {
				display: block;
				height: 22px;
				left: 9px;
				position: relative;
				top: 9px;
			}
		}

		td {
			//padding: 6px !important;
		}

		.edit-action {
			padding: 5px;
			width: 70px;

			&:hover {
				animation-duration: 0.5s;
				animation-fill-mode: forwards;
				animation-name: grey-fade-in;

				@keyframes grey-fade-in {
					to {
						background: rgba(0, 0, 0, .07);
						border-radius: 5px;
						cursor: pointer;
					}
				}
			}
		}

		.download-report {
			padding: 5px;
     	 width: 130px;

			&:hover {
				animation-duration: 0.5s;
				animation-fill-mode: forwards;
				animation-name: grey-fade-in;

				@keyframes grey-fade-in {
					to {
						background: rgba(0, 0, 0, .07);
						border-radius: 5px;
						cursor: pointer;
					}
				}
			}
		}
	}

	.bottom-bar {
		background: rgba(0, 0, 0, .3);
		bottom: 0;
		box-shadow: inset 0px 10px 18px -10px rgba(0, 0, 0, 0.25);
		height: 55px;
		padding-top: 12px;
		// position: absolute;
		position: relative;
		text-align: center;
		width: 100%;
		-webkit-box-shadow: inset 0px 10px 18px -10px rgba(0, 0, 0, 0.25);
		-moz-box-shadow: inset 0px 10px 18px -10px rgba(0, 0, 0, 0.25);
	}

	.ant-table-row {

		td {
		}
	}

	.filters-wrapper {
		overflow: hidden;
		position: relative;

		&.null {
			height: 0;

			.filters-wrapper-inner {
				transform: translateY(-200px);
			}
		}

		&.show {
			animation-duration: 0.75s;
			animation-fill-mode: forwards;
			animation-name: show-parent-tab-rules;
			z-index: 800;

			@keyframes show-parent-tab-rules {
				from {
					height: 0;
				}

				to {
					height: 95px;
				}
			}

			.filters-wrapper-inner {
				animation-duration: 0.75s;
				animation-fill-mode: forwards;
				animation-name: show-child;

				@keyframes show-child {
					from {
						transform: translateY(-200px);
					}

					to {
						transform: translateY(0);
					}
				}
			}
		}

		&.hide {
			animation-duration: 0.3s;
			animation-fill-mode: forwards;
			animation-name: hide-parent;
			overflow: hidden;
			position: relative;
			z-index: 800;

			@keyframes hide-parent {
				from {
					height: 110px;
				}

				to {
					height: 0;
				}
			}

			.filters-wrapper-inner {
				animation-duration: 0.3s;
				animation-fill-mode: forwards;
				animation-name: hide-child;

				@keyframes hide-child {
					from {
						transform: translateY(0);
					}

					to {
						transform: translateY(-200px);
					}
				}
			}
		}
	}

	h3 {
		font-size: 1em;
		color: rgba(0, 0, 0, .7);
	}

}
