.query-filters {
  box-shadow: inset 0px 156px 24px -133px rgba(59,73,86, 0.05);
  height: 167px;
  left: 0;
  position: absolute;
  right: 0;
  top: 72px;
  -moz-box-shadow: inset 0px 156px 24px -133px rgba(59,73,86, 0.05);
  -webkit-box-shadow: inset 0px 156px 24px -133px rgba(59,73,86, 0.05);

  .filters-inner {
    color: #fff;
    padding: 15px 20px;

    label.default {
      display: block;
      margin-bottom: 10px;
    }
  }
}
