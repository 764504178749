.modal-alert-card{
    .alert-close-section{
        padding: 10px;
        .alert-close-body{
            padding: 20px;
            .alert-close-content-wrapper{
                border: 1px solid #d9d9d9;
                border-radius: 5px;
                padding: 25px;
            }
        }
    }
}