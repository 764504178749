
.alert-info-card {

  .notification-count {
    font-size: 16px;
    color: #06068a;
    padding-left: 10px;
    font-weight: bolder;
  }

  .left-card {
    height: 100%;

    .alert-resume-text {
      padding-bottom:8px;
    }
  }

  .right-card {
    height: 100%;

    .resume-alert-info {

      text-align:left;
      padding-Left:30px;

      .count-info {
        font-size: 16px;
        font-weight: bold;
        color: #06068a;
        padding-bottom:5px
      }
    }
  }

  .search-rut {
    float: right;
    width: 50%;
  }
}