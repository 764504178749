
.principal-rules-category {


  .row-principal-content {
    padding-top: 10px;
    margin-left: 15px;
    width: 93%;

    .Register-name {
      text-align: left;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }

    .rul-name {
      font-size: 14px;
      color: #06068a;
    }

    .total-level-risk {
      width: 100%;
    }

    .level-risk-indicator {

      background: lightblue;
      border: 1px lightblue;
      height: 80%;

      .text-risk-indicator {
        padding: 12px 0 12px 0;
        font-size: 17px;
        color: white;
      }
    }
  }

  .level-risk-value {
    float: left;
    margin-bottom: -11px;
    padding-left: 15px;
    font-size: 14px;
    color: #06068a;
  }

  .show-all-alerts-by-category {
    font-weight: bold;
    border: none;
    background-color: inherit;
    font-size: 14px;
    cursor: pointer;
    display: inline-block;
    color: #06068a;


    .text {
      text-decoration: underline #06068a;
    }
  }
}