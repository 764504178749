
.step2file-content{
    padding: 20px;
    .step2file-title{
        font-size: 20px;
        margin-bottom: 20px;
    }

    .step2file-field-label{
        font-size: 16px;
        margin-bottom: -10px;
    }
    .plantilla {
      text-align: right;
      padding-right: 5px;
      margin-top: 3px;
      font-size: 1.1em;
    }

}
