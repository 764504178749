.inside-alert-stacked-chart {

  height: 300px;

  .all-content {
    text-align: left;
  }
  .total-content {
    width: 100%;
    justify-content: space-between;
    font-size: 17px;
    font-weight: bold;
    margin: 0 auto;
    font-family: Arial, sans-serif;
    padding-top: 3px;
    text-align: right;

    .show-content-alert {
      text-align: left;
    }
  }

  .show-all-alert-content {
    font-weight: bold;
    border: none;
    background-color: inherit;
    font-size: 14px;
    cursor: pointer;
    display: inline-block;;
  }

  .title {
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    font-weight: bold;
    width: 45%;
    margin-left: 4%;
    margin-top: 10px;
    font-family: Arial, sans-serif;
  }

  .ant-table-tbody {
    td, .ant-table-row-cell-break-word {
      padding: 2px !important;
    }
  }

  .inside-alert-stacked-row {
    margin: 1px;

    .inside-alert-stacked-row-total {
      float: left;
      width: 5%;
      padding: 3px 0px 2px 7px;
      text-align: left;
      font-size: 8px;
    }

    td, .inside-alert-stacked-row-chart {
      float: left;
      border-radius: 3px;
      border: 0.7px solid rgba(0, 0, 0, .4);

      .risk-chart-row {
        float: left;
        text-align: right;

        .risk-chart-cell {
          height: 20.6px;
          margin-right: 0.3px;
          padding: 2px;
          color: white;
          font-size: 6px;
          .section-info {
            padding: 4px;
          }
        }

        &:first-child .risk-chart-cell {
          border-top-left-radius: 3px;
          border-bottom-left-radius: 3px;
        }

        &:last-child .risk-chart-cell {
          border-top-right-radius: 3px;
          border-bottom-right-radius: 3px;
          margin-right: unset;
        }
      }
    }
  }
}

td, .ant-table-row-cell-break-word {

  .inside-alert-stacked-row-total {
    float: left;
    width: 5%;
    padding: 4px 0px 2px 11px;
    text-align: left;
    font-size: 10px;
  }
  .inside-alert-stacked-row-chart {
    float: left;
    border-radius: 4px;
    border: 0.7px solid rgba(0, 0, 0, .4);

    .risk-chart-row {
      float: left;
      text-align: right;

      .risk-chart-cell, .inside-alert-stacked-row-chart {

        background: #2cbe10;
        height: 20.6px;
        margin-right: 0.3px;
        padding: 2px;
        color: white;
        font-size: 6px;
        .section-info {
          padding: 4px;
        }
      }

      &:first-child .risk-chart-cell {
        border-top-left-radius: 3px;
        border-bottom-left-radius: 3px;
      }

      &:last-child .risk-chart-cell {
        border-top-right-radius: 3px;
        border-bottom-right-radius: 3px;
        margin-right: unset;
      }
    }
  }
}
