.logoForm {
  height: 50px;
  float: right;
  img {
    height: 50px;
    margin-right: 10px;
    vertical-align: inherit;
    z-index: 10;
  }
}

.logo {
  height: 40px;
  float: left;

  img {
    height: 50px;
    margin: 0;
    vertical-align: inherit;
  }
}

