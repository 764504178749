#query-risk {

  #content{
    width: 100%;
    padding: 5px;
  }

  .ant-form-item {
    float: left;
    margin-bottom: 0;
  }

  .page {
    position: relative;

    &#real-person-page {
      z-index: 1000;
    }

    .page-toolbar {
      position: relative;

      &.is-advanced-search {
        animation-duration: 0.3s;
        animation-fill-mode: forwards;
        animation-name: show-advanced-search-filters
      }
    }

    .page-content {
      overflow: visible;
      padding: 10px 20px 20px 20px;

      &.empty {
        background: rgba(255, 255, 255, .5) url('./bg.png') no-repeat center center;
      }

      &.grow-15 {
        height: 2245px;
        transition: height 0.8s ease-out;
      }

      &.grow-10 {
        height: 1545px;
        transition: height 0.8s ease-out;
      }

      &.grow-5 {
        height: 845px;
        transition: height 0.8s ease-out;
      }

      &.shrink {
        height: 550px;
        transition: height 1s ease-out;
      }

      .page-content-inner {
        &.fade-out {
          opacity: 0;
          transition: opacity 0.8s ease-out;
        }

        .table-headers {
          background: #a1aab7;
          border-radius: 10px;
          color: #fff;
          margin-bottom: 15px;

          .ant-row {
            border-radius: 5px;
            overflow: hidden;

            .ant-col {
              line-height: 40px;
              height: 40px;
              text-align: center;

              &:nth-child(even) {
                background: #a1aab7;
              }

              &:nth-child(odd) {
                background: #8b98a8;
              }

              &:last-child {
              }
            }
          }
        }

        .zero-results {
          height: 550px;

          center {
            font-size: 1.5em;
          }
        }
      }
    }

    .page-footer {
      bottom: 0;
      height: 70px;
      position: footer;
      width: 100%;
    }
  }

  .ant-spin {
    font-size: 2em;
    left: 50%;
    margin-left: -16px;
    margin-top: -16px;
    position: absolute;
    top: 57%;
  }
}

.leaves-screen-to-left {
  animation-duration: 0.3s !important;
  animation-fill-mode: forwards !important;
  animation-name: page-leaves-screen-to-left !important;
  position: absolute;
  -webkit-animation-duration: 0.3s !important;
  -webkit-animation-fill-mode: forwards !important;
  -webkit-animation-name: page-leaves-screen-to-left !important;
}

@keyframes page-content-grow-15 {
  from {
    height: 550px;
  }

  to {
    height: 2090px;
  }
}

@keyframes page-content-grow-10 {
  from {
    height: 550px;
  }

  to {
    height: 1390px !important;
  }
}

@keyframes page-content-grow-5 {
  from {
    height: 550px;
  }

  to {
    height: 690px;
  }
}

@keyframes page-content-shrink {
  to {
    height: 550px;
  }
}

@keyframes page-leaves-screen-to-left {
  from {
    left: 0;
    opacity: 1;
    top: 0;
  }

  to {
    opacity: 0;
    left: -100%;
    top: 0;
  }
}

@keyframes transition-profile-page-enters {
  from {
    left: 100%;
    opacity: 0;
  }

  to {
    left: 50%;
    margin-left: -600px;
    opacity: 1;
  }
}

@keyframes show-advanced-search-filters {
  from {
    height: 73px;
  }

  to {
    height: 167px;
  }
}
