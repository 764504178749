
.principal-rules-register {


  .rul-name {
    font-size: 14px;
    color: #06068a;
    padding: 4px 10px 4px 5px;
    border: 2px solid rgba(0,0,0, .1);
  }

  .row-table-content{
    margin-top: 15px;
    width: 100%;

    .content-register-table {
      border-left: 1px solid;
      border-bottom: 1px solid;

      .record-cell {
        padding: 10px 5px;
        border-right: 1px solid;
        border-top: 1px solid;
      }
    }

    .alert-name {
      color: #06068a;
      text-align: left;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      font-weight: initial;

      left: 100%;
      width: 98%;
      padding-left: 5px;
    }

    .alert-date {
      text-align: right;
      font-weight: initial;
      padding-right: 10px;
    }

  }

  .level-risk-value {
    float: left;
    margin-bottom: -11px;
    padding-left: 15px;
    font-size: 14px;
    color: #06068a;
  }

  .show-all-alerts {
    font-weight: bold;
    border: none;
    background-color: inherit;
    font-size: 14px;
    cursor: pointer;
    display: inline-block;
    color: #06068a;

    .text {
      text-decoration: underline #06068a;
    }
  }
}