.conflicts-of-interest {
	.sidebar {
		min-height: auto;

		.sidebar-inner {
			padding-right: 0;

			.menu-block {
				margin-bottom: 20px;

				h3 {
					color: rgba(0, 0, 0, .5);
					font-size: 1em;
					line-height: 1em;
					margin: 0 0 8px;
					padding: 0 0 0 12px;
					position: relative;

					img {
						height: 13px;
						opacity: 0.5;
						left: 0;
						position: absolute;
						top: 0;
					}
				}

				ul {
					list-style-type: none;
					margin: 0;
					padding: 0;

					li {
						background: rgba(0, 0, 0, .05);
						border-bottom: 1px rgba(0, 0, 0, .05) solid;
						color: rgba(0, 0, 0, .5);
						font-size: 0.9em;
						height: 40px;
						line-height: 40px;
						margin-right: 22px;
						text-indent: 15px;


						&:first-child {
							border-top-left-radius: 5px;
							border-top-right-radius: 5px;
						}

						&:last-child {
							border-bottom: 1px rgba(0, 0, 0, .1) solid;
							border-bottom-left-radius: 5px;
							border-bottom-right-radius: 5px;
							margin-bottom: 0;
						}

						.anticon {
							display: none;
						}

						a {
							color: rgba(0, 0, 0, .5);
						}

						&:hover {
							background: rgba(0, 0, 0, .085);
							cursor: pointer;

							a {
								//border-bottom: 1px rgba(0, 0, 0, .2) solid;
							}
						}

						&.active {
							background: #f2f1f1;
							border: 1px rgba(0, 0, 0, .1) solid;
							border-radius: 5px;
							box-shadow: 0px 3px 3px 0px rgba(0,0,0,0.05);
							color: rgba(0, 0, 0, .5) !important;
							font-size: 0.9em;
							font-weight: bold !important;
							position: relative;
							left: -5px;
							text-indent: 19px;
							width: 234px;
							-webkit-box-shadow: 0px 3px 3px 0px rgba(0,0,0,0.05);
							-moz-box-shadow: 0px 3px 3px 0px rgba(0,0,0,0.05);

							.anticon {
								display: block;
								font-weight: bold;
								opacity: 0.85;
								position: absolute;
								right: 15px;
								top: 14px;
							}
						}
					}
				}
			}
		}
	}
}
