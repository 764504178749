#tab-content-1 {
  font-size: 1.2em;
  padding: 70px 120px;

  .ant-row {
    .ant-col:last-child {
      .col-inner {
        padding-right: 0;
      }
    }

    .ant-col {
      .col-inner {
        min-height: 100px;
        padding-right: 25px;

        &.list {
          border-radius: 5px;

          strong {
            background: rgba(0, 0, 0, .3);
            border-top-left-radius: 5px;
            border-top-right-radius: 5px;
            color: #fff;
            padding: 10px 15px;
            text-align: center;
          }
        }

        strong {
          border-bottom: 1px rgba(0, 0, 0, .1) solid;
          display: block;
          margin-bottom: 10px;
        }
      }
    }
  }
  .ventas-chart {
    padding: 12px;
    overflow: hidden;
  }
}

.modal-transf-detalle {

  .ant-table {
    font-size: smaller;
    .ant-table-thead {
      background-color: #5A6AC1;

      .ant-table-header-column {
        color: white;
      }
    }
    .trans-detalle-row-0 {
      background-color: #DCE0F1;
    }
  }
}