.quiebras {
  .cake {
    height: 125px;
    padding: 0;
    position: relative;
    width: 125px;

    .cake-is-loading {
      .ant-spin {
        left: 50%;
        margin: -10px 0 0 -10px;
        position: absolute;
        top: 50%;
      }
    }

    .results-number {
      color: rgba(0, 0, 0, .7);
      font-size: 1.5em;
      font-weight: 900;
      position: absolute;
      text-align: center;
      top: 42px;
      width: 100%;
      right: -3px!important;
    }

    .results-label {
      color: rgba(0, 0, 0, .7);
      font-size: 0.8em;
      position: absolute;
      text-align: center;
      top: 68px;
      width: 100%;
      right: -3px!important;
    }
  }
}
