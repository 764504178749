#content {
  .page-header {
    background-color: rgba(255, 255, 255, .5);
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    box-shadow: 0px 8px 17px 0px rgba(0,0,0,0.06);
    height: 90px;
    position: relative;
    padding: 20px;
    z-index: 1000;
    -webkit-box-shadow: 0px 8px 17px 0px rgba(0,0,0,0.06);
    -moz-box-shadow: 0px 8px 17px 0px rgba(0,0,0,0.06);

    .page-icon {
      border-radius: 25px;
      color: #fff;
      float: left;
      font-size: 25px;
      height: 50px;
      line-height: 50px;
      margin-right: 15px;
      text-align: center;
      width: 50px;
      margin: 0px 10px 0px 0px;
    }

    .page-title {
      color: rgba(0, 0, 0, .5);
      line-height: 1em;
      margin-bottom: 0;
      margin-top: 5px;
      padding: 0;
    }

    .page-description {
      color: rgba(0, 0, 0, .5);
      margin: 0;
    }
  }

  .pageTitle{
    position: relative;
  }

  .advice{
    width: 75%;
    background-color: #fff2cc;
    border-style: solid;
    border-color: #fec62a;
    display: flex;
    position:absolute;
    border-radius: 10px;
    justify-content: space-between;
    right: 10px;
    padding-top: 5px;
    padding-bottom: 10px;

    .adviceMsgContainer{
    
    }

    .adviceTitle {
      margin-left: 10px;
    }

    .adviceMsg{
      margin-top: 5px;
      display: block;
      margin-left: 10px;
    }

    .adviceButton {
      display: block;
      margin-top: auto;
      margin-bottom: auto;
      margin-right: 10px;
      padding-top: 10px;
    }
  }


}
