#searchbox {
  display: flex;
  
  .inputSearch{

  }

  .searchBtn{
    margin-left: 13rem;
  }
}

