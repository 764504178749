.modal-new-simulation {
  -webkit-box-shadow: 0px 0px 105px -1px rgba(0,0,0,0.48);
  -moz-box-shadow: 0px 0px 105px -1px rgba(0,0,0,0.48);
  box-shadow: 0px 0px 105px -1px rgba(0,0,0,0.48);
  border-radius: 10px;
  overflow: hidden;
  width: 1000px !important;
  padding: 0;

  .ant-modal-close {
    z-index: 30;

    .ant-modal-close-x {
      line-height: 2.3em;
      height: auto !important;
      width: 40px !important;
    }
  }

  .ant-modal-body {
    padding: 0;

    .new-simulation-head {
      overflow: hidden;
      position: relative;
      z-index: 10;
      //-webkit-box-shadow: 0px 15px 22px -1px rgba(0,0,0,0.1);
      //-moz-box-shadow: 0px 15px 22px -1px rgba(0,0,0,0.1);
      //box-shadow: 0px 15px 22px -1px rgba(0,0,0,0.1);

      ul {
        list-style-type: none;
        margin: 0;
        padding: 0;

        li.step {
          align-items: center;
          display: flex;
          justify-content: center;
          background-color: rgba(0, 0, 0, .1);
          float: left;
          border-right: 1px rgba(0, 0, 0, .3) solid;
          height: 70px;
          width: 33.333%;
          -webkit-box-shadow: inset 0px -115px 88px -13px rgba(0,0,0,0.2);
          -moz-box-shadow: inset 0px -115px 88px -13px rgba(0,0,0,0.2);
          box-shadow: inset 0px -115px 88px -13px rgba(0,0,0,0.2);

          .step-number {
            background: rgba(0, 0, 0, .1);
            border-bottom: 2px rgba(0, 0, 0, .2) solid;
            color: rgba(255, 255, 255, .6);
            border-radius: 20px;
            height: 40px;
            margin: 0 15px 0 0;
            font-size: 1.6em;
            line-height: 40px;
            text-align: center;
            width: 40px;
          }

          .step-name {
            h4 {
              color: rgba(255, 255, 255, .7);
              padding: 0 !important;
              margin: 0 !important;
              line-height: 50px;
              font-size: 1.2em;
              height: 50px;
            }
          }

          &.active {
            background-color: #fff;
            position: relative;
            z-index: 20;
            -webkit-box-shadow: 0px 0px 105px -1px rgba(0,0,0,0.13);
            -moz-box-shadow: 0px 0px 105px -1px rgba(0,0,0,0.13);
            box-shadow: 0px 0px 105px -1px rgba(0,0,0,0.13);

            .step-number {
              color: rgba(0, 0, 0, .35);
            }

            .step-name {
              h4 {
                color: rgba(0, 0, 0, .4);
              }
            }
          }
        }
      }
    }

    .new-simulation-body {
      position: relative;

      .content-wrap {
        background: #eee;
        border: 1px rgba(0, 0, 0, .1) solid;
        border-radius: 10px;
        margin: 20px;
        padding: 0px;

        .content-wrap-inner {
        }

        &.index-0 {
          .content-wrap-inner {
            padding: 15px 0 25px;
            margin: 0 auto;
            width: 600px;

            label {
              color: rgba(0, 0, 0, .5);
              display: block;
              padding-bottom: 5px;
              text-align: center;
            }
          }
        }

        &.index-1 {
          .content-wrap-inner {
            overflow-y: scroll;
            height: 400px;
          }
        }

        &.index-2 {
          padding: 0;

          .content-wrap-inner {
            height: 400px;

            .ant-col {
              padding: 0;
            }

            center {
              -webkit-box-shadow: 0px 19px 24px 0px rgba(0,0,0,0.12);
              -moz-box-shadow: 0px 19px 24px 0px rgba(0,0,0,0.12);
              box-shadow: 0px 19px 24px 0px rgba(0,0,0,0.12);
              background: rgba(252, 248, 3, .3);
              border: 1px rgba(0, 0, 0, .1) solid;
              border-top-left-radius: 10px;
              border-top-right-radius: 10px;
              font-size: 0.9em;
              margin-bottom: 40px;
              padding: 20px;
            }

            .progress-wrap {
              -webkit-box-shadow: inset 0px -143px 152px 0px rgba(0,0,0,0.21);
              -moz-box-shadow: inset 0px -143px 152px 0px rgba(0,0,0,0.21);
              box-shadow: inset 0px -143px 152px 0px rgba(0,0,0,0.21);
              border-radius: 10px;
              overflow: hidden;
              margin: 0 auto;

              .progress-wrap-inner {
                padding: 20px;

                .ant-progress {
                  display: block;
                  margin: 0 auto;
                  text-align: center;

                  svg {
                  }
                }
              }

              .bottom-bar {
                background: rgba(0, 0, 0, .5);
                color: rgba(255, 255, 255, .5);
                padding: 10px 0;
                text-align: center;
              }
            }

            .progress-container {
              -webkit-box-shadow: inset 0px -143px 152px 0px rgba(0,0,0,0.21);
              -moz-box-shadow: inset 0px -143px 152px 0px rgba(0,0,0,0.21);
              border-radius: 10px;
              box-shadow: inset 0px -143px 152px 0px rgba(0,0,0,0.21);
              padding: 10px;
              border: 1px rgba(0, 0, 0, .2) solid;
              margin: 0 auto;
              width: 70%;
            }

            ul {
              list-style-type: none;
              padding: 0;
              margin: 0 0 0 10px;

              li {
                -webkit-box-shadow: inset 0px -143px 152px 0px rgba(0,0,0,0.05);
                -moz-box-shadow: inset 0px -143px 152px 0px rgba(0,0,0,0.05);
                box-shadow: inset 0px -143px 152px 0px rgba(0,0,0,0.05);
                background: rgba(0, 0, 0, .2);
                border-radius: 5px;
                font-size: 0.95em;
                margin: 0 0 5px;
                overflow: hidden;

                label {
                  background: rgba(0, 0, 0, .2);
                  color: rgba(255, 255, 255, .5);
                  display: block;
                  padding: 2px 10px;
                }

                p {
                  color: rgba(255, 255, 255, .5);
                  margin: 0;
                  padding: 2px 10px;
                }
              }
            }
          }
        }
      }
    }

    .new-simulation-foot {
      -webkit-box-shadow: inset 0px 152px 18px -143px rgba(0,0,0,0.15);
      -moz-box-shadow: inset 0px 152px 18px -143px rgba(0,0,0,0.15);
      box-shadow: inset 0px 152px 18px -143px rgba(0,0,0,0.15);
      background: rgba(0, 0, 0, .2);
      border-top: 1px rgba(0, 0, 0, .2) solid;
      height: 70px;
      display: flex;
      align-items: center;
      justify-content: center;

      #go-to-rules, #run-simulation {
        background-color: green !important;
        border: 0 !important;
      }
    }
  }

  .downtime {
    padding-top: 30px;
    h3, p {
      text-align: center;
      margin: 0;
      padding: 0;
    }
    h3 {
      color: rgba(0, 0, 0, .5);
    }

    p {
      color: rgba(0, 0, 0, .5);

    }
    img {
      height: 200px !important;
      display: block;
      opacity: 0.5 !important;
      margin: 30px auto !important;
    }
  }
}
