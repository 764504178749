.user-details-block {
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  margin-bottom: 0 !important;
  -webkit-box-shadow: inset 0px 135px 116px -146px rgba(0,0,0, .7);
-moz-box-shadow: inset 0px 135px 116px -146px rgba(0,0,0, .7);
box-shadow: inset 0px 135px 116px -146px rgba(0,0,0, .7);


  .block-title {
    text-align: center;

    .details {
      ul {
        color: rgba(255, 255, 255, .7);
        left: 382px;
        list-style-type: none;
        padding: 1px 0 0 0;
        position: relative;

        li {
          float: left;
          margin-right: 20px;
        }
      }
    }
  }

  .block-content {
    position: relative;
    min-height: 105px;

    .img-wrap {
      -webkit-box-shadow: inset -5px 0px 9px 2px rgba(0,0,0,0.05);
      -moz-box-shadow: inset -5px 0px 9px 2px rgba(0,0,0,0.05);
      box-shadow: inset -5px 0px 9px 2px rgba(0,0,0,0.05);
      background: rgba(0, 0, 0, .05);
      bottom: 0;
      position: absolute;
      left: 0;
      margin-top: -55px;
      top: 55px;
      width: 150px;

      .img-wrap-inner {
        background: rgba(0, 0, 0, .1);
        border-radius: 50px;
        height: 100px;
        position: relative;
        left: 23px;
        width: 100px;
        top: 50%;
        margin-top: -50px;

        img {
          opacity: 0.3;
          position: relative;
          height: 60px;
          left: 25px;
          top: 20px;
        }
      }
    }
    .block-content-inner {
      padding: 20px 10px 0 190px;
      overflow: hidden;

      .field {
        float: left;
        min-height: 50px;
        margin-bottom: 10px;
        width: 25%;

        &.field-long {
          width: 33.3% !important;
        }

        .field-inner {
          font-size: 0.9em;
          font-weight: bold;
          color: rgba(0, 0, 0, .5);
          min-height: 60px;
          width: 85%;

          label {
            font-weight: normal;
            color: rgba(0, 0, 0, .5);
            display: block;
            border-bottom: 1px rgba(0, 0, 0, .1) solid;
            margin-bottom: 5px;
            width: 100% !important;
            overflow: hidden;
            white-space: nowrap;
          }
        }
      }
    }
  }

  .pep-block {
    clearl: left !important;
    padding-top: 20px;
    overflow: hidden;
    width: 100%;
  }
}
