.inside-compliance{
  .inside-relations-cdi-pie-chart{

    .small-text-relations-cdi{
      text-align: center;
      font-weight: 600;
      font-size: 16px;
      white-space: nowrap;
    }
  }
}