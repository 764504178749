.page {
  border-radius: 5px;
  mix-blend-mode: ligthen;
  overflow: hidden;
  width: 100%;
  -webkit-box-shadow: 0px 0px 5px -1px rgba(0,0,0,0.25);
  -moz-box-shadow: 0px 0px 5px -1px rgba(0,0,0,0.25);
  box-shadow: 0px 0px 5px -1px rgba(0,0,0,0.25);

  &.collapsed-true {
    padding-right: 300px;
    animation-name: sideMenuAnimationLeft;
    animation-duration: 1s;
  }

  &.collapsed-false {
    padding-right: 0px;
    animation-name: sideMenuAnimationRight;
    animation-duration: 1s;
  }

  @keyframes sideMenuAnimationLeft {
    0% {padding-right: 0px;}
    100% {padding-right: 300px;}
  }

  @keyframes sideMenuAnimationRight {
    0% {padding-right: 300px;}
    100% {padding-right: 0px;}
  }
}
