#div-modal-ubos {
  td, th {
    font-size: 12px !important;
  }
  div.nowrap {
    //white-space: nowrap;
  }
  td.error-E03, td.error-E04 {
    background-color: rgba(255, 0, 0, 0.2);
  }
  td.error-E05 {
    background-color: rgba(255, 255, 0, 0.2);
  }

  h2 {
    text-align: center;
    margin-bottom: 0px;
  }
  h3 {
    text-align: center;
  }
  h3.title-section {
    background-color: rgba(0, 0, 0, 0.3);
    margin: 10px 0px 0px 0px;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    color: rgba(255, 255, 255, 1);
    font-weight: bold;
    padding: 7px 0;
  }

  .ant-form-item {
    margin-bottom: 0px;
  }

  .monitoreo {
    width: 120px;
    height: 50px;
    margin: auto;
    margin-bottom: 25px;

    .onboarding {
      border-radius: 15px;
      height: 30px;
      width: 30px;
      float: left;
      margin: 5px;
    }
    .risk-GRAY {
      background-color: rgba(0, 0, 0, 0.2);
    }
  }
  td.table-cell {
    padding: 0px;
  }
  div.btn-actions-form {
    float: right;

    button {
      margin-left: 5px;
    }
  }
  h3.no-info {
    color: rgba(0, 0, 0, 0.5);
  }

  h3.no-info-red {
    color: rgba(255, 0, 0, 0.7);
  }
}
.message-buttons-confirm {
  float: right;
}
p.warning-message {
  color: #e8aa30;
}
p.confirm-message {
  margin-bottom: 0.5em;
  line-height: normal;
}

.addComment {
  background: rgba(0, 0, 0, .1);
  border-radius: 18px;
  cursor: pointer;
  height: 36px;
  line-height: 26px;
  margin-bottom: 10px;
  margin-top: 5px;
  padding: 5px 5px;
  text-indent: 5px;
  width: 200px;

  span {
    float: left;
  }

  .ant-btn {
    float: right;
    position: relative;
    top: 1px;
  }
}

.modal-tree {
  .entity-type-Natural {
    color: rgba(0,0,255,0.8);
  }
}
