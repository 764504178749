.div-modal-ubofinder-record{
    td.error-E03, td.error-E04 {
        background-color: rgba(255, 0, 0, 0.2) !important;
    }
    td.error-E05 {
       background-color: rgba(255, 255, 0, 0.2) !important;
    }

    .mallas-propiedad-main-wrapper {
        padding: 10px;
    }

    .separator-line {
        border: none;
        height: 0.5px;
        background-color: rgba(0, 0, 0, 0.15);
        margin: 10px 0;
    }
}