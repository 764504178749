.stakeholder {
	.row-loading {
		padding: 100px;
		text-align: center;
	}

	.minisite-stakeholder {
    	background: url('../InterestConflicts/bg.png') repeat-y top left;
		//min-height: 1100px;

		div.content {
			margin-top: -5px;
		}
	}
	.header {
	  animation-name: header-slide-down;
	  animation-duration: 1s;
	  //background: #293443;
	  background: rgba(255, 255, 255, .5);
	  height: 50px;
	  left: 0;
	  margin-bottom: 10px;
	  overflow: hidden;
	  padding: 0;
	  right: 0;
	  top: 0;
	  z-index: 1;
	  -webkit-box-shadow: 0px 3px 5px -1px rgba(0,0,0,0.08);
	  -moz-box-shadow: 0px 3px 5px -1px rgba(0,0,0,0.08);
	  box-shadow: 0px 3px 5px -1px rgba(0,0,0,0.08);

	  .current-user {
		float: right;
		width: unset;
	  }
	}

	.instructions-box{
		animation-duration: 2s;
		animation-name: instructions-fade-in;
		background: rgba(255, 255, 255, 0.411);
		border-radius: 30px;
		padding: 20px;
		text-align: left;
		-webkit-box-shadow: 0px 0px 15px 1px rgba(255, 0, 0, 0.1);
		-moz-box-shadow: 0px 0px 15px 1px rgba(0,0,0,0.1);
		box-shadow: 0px 0px 15px 1px rgba(0,0,0,0.1);
		//width: 22%;
	  }

	.page-content {
		min-height: 655px;
	}

	.login {
		position: unset;

		.login-content {
			padding: 80px;
		}
	}

	.login-container {
		position: relative;

		.login-box{
			width: 496px;
			margin-left: auto;
			margin-right: auto;
			text-align: center;
		}
		.login-box h2.title {
			text-align: center;
		}
	}

  .bottom-bar {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    margin-left: 15px;
    width: 892px !important;
  }

  .forgot-container{
	margin: 10px;
    text-align: center;
    margin-right: auto;
    margin-left: auto;
  }

  .forgot {
	color: #6b6f7f !important;
  }

  .captchaVerify{
	  margin-left: auto;
	  margin-right: auto;
	  width: fit-content;
  }

  .crud-label{
	text-align: left;
	font-weight: 500;
	.ant-form-item-label >label{
		color: rgba(0, 0, 0, 0.6)
	}
  }

// 	#footer {
//     color: rgba(0,0,0,.5);
// 		position: fixed;
//     top: 100%;
//   	transform: translateY(-100%);
// 		width: 100%;
// }
  @keyframes login-fade-in {
	from {
	  margin-top: -10px;
	  opacity: 0;
	}

	to {
	  margin-top: 0;
	  opacity: 1;
	}
  }

  @keyframes instructions-fade-in {
	from {
	  margin-left: -30px;
	  opacity: 0;
	}

	to {
	  margin-top: 0;
	  opacity: 1;
	}
  }
}
