.investigacion-cdi-main-wrapper{
    .field-section-row{
        .info-yellow-card-cdi{
            background-color: #FAFFBD;
            border-radius: 4px;
            margin: 3px;
            text-align: center;
            vertical-align: middle;
        }
    }
}

