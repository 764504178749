.cdi-relaciones{
	.onb-form-manager {
		.form-manager-content{
			.ant-tabs-bar{
			margin: unset;
			}
		}

		.filters-wrapper {
			overflow: hidden;
			position: relative;

			&.null {
				height: 0;

				.filters-wrapper-inner {
					transform: translateY(-130px);
				}
			}

			&.show {
				animation-duration: 0.75s;
				animation-fill-mode: forwards;
				animation-name: show-parent-onb-forms;
				z-index: 800;

				@keyframes show-parent-onb-forms {
					from {
						height: 0;
					}

					to {
						height: 90px;
					}
				}

				.filters-wrapper-inner {
					animation-duration: 0.75s;
					animation-fill-mode: forwards;
					animation-name: show-child-onb-forms;

					@keyframes show-child-onb-forms {
						from {
							transform: translateY(-130px);
						}

						to {
							transform: translateY(0);
						}
					}
				}
			}

			&.hide {
				animation-duration: 0.3s;
				animation-fill-mode: forwards;
				animation-name: hide-parent-onb-forms;
				overflow: hidden;
				position: relative;
				z-index: 800;

				@keyframes hide-parent-onb-forms {
					from {
						height: 90px;
					}

					to {
						height: 0;
					}
				}

				.filters-wrapper-inner {
					animation-duration: 0.3s;
					animation-fill-mode: forwards;
					animation-name: hide-child-onb-forms;

					@keyframes hide-child-onb-forms {
						from {
							transform: translateY(0);
						}

						to {
							transform: translateY(-130px);
						}
					}
				}
			}
		}
	}
}