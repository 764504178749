.register {
	.page-content {
		padding: 0;
		position: relative;
		min-height: 1385px;
		padding-top: 2px;

		.items {
			min-height: 690px;
			overflow: hidden;
		}

		.page-footer {
			background: transparent;
			bottom: 0px;
			position: absolute;
			right: 0px;
			left: 0px;
			margin-left: 300px;
		}
	}

	&.register-new {
		.page-content {
			min-height: 1100px;
		}
		.page-footer {
			margin-left: 260px;
		}
	}

	.btn-actions {
		position: absolute;
		top: 4px;
		right: 0px;

		button {
			margin: 2px;
			width: 100%;
		}
	}

	.top-register-buttons {
		.ant-btn {
			float: left;
			margin-left: 10px;
			width: auto;
		}
	}

	.spinner-overlay {
	bottom: 70px;
	left: 300px;
	position: absolute;
	right: 0;
	top: 163px;

		.ant-spin {
			left: 50%;
			margin: -20px 0 0 -20px;
			position: absolute;
			top: 35%;
		}
	}

	.register-table{
		margin-bottom: 72px;
  // INICIO DE TRANSFERENCIA DE ESTILOS
		.number-register-wrapper {
			border-radius: 0.5rem;
			border: 1px solid #0000002d!important;
		}
		.name-col-table{
			button {
				text-align: left;
				padding: 0px;
				width: 100%;
				white-space: normal;
			}
		}

		.pagination-formtable{
			margin-top:20px;
		}

		.ant-table-row-cell-break-word{
			padding: 2px;
		}

		.solicitudes-card{
			background-color: #FAFFBD;
			border-radius: 4px;
			margin: 3px;
			text-align: center;
			vertical-align: middle;
		}

		.solicitudes-card-registros{
			background-color: #FAFFBD;
			border-radius: 4px;
			margin: 3px;
			text-align: center;
			vertical-align: middle;
		}


		.ellipsis-icon-onb{
			text-align: center;
		}

		.text-center-type-onb{
			text-align: center;
		}


		.total-onb-card{
			background-color: #fff;
			border-radius: 4px;
			margin: 3px;
			text-align: center;
			vertical-align: middle;
		}

		.top-bar {
			height: 40px;
			// padding-left: 14px;
			padding-top: 14px;
			position: relative;
			top: -20px;

			.advanced-search {
				background-color: transparent;
				border-bottom: 1px;
				border-bottom-style: solid;
				border-bottom-color: transparent;
				border-radius: 5px;
				color: rgba(0, 0, 0, .5);
				cursor: pointer;
				padding: 5px 10px 5px 0;
				position: absolute;
				right: 14px;
				top: 14px;
				.anticon:first-child {
					font-size: 0.8em;
					margin-left: 10px;
					margin-top: -3px;
				}

				.anticon:last-child {
					font-size: 0.85em;
				}

				&.on {
					animation-duration: 0.5s;
					animation-fill-mode: forwards;
					animation-name: filters-are-open;

					@keyframes filters-are-open {
						to {
							background-color: rgba(0, 0, 0, .075);
							border-bottom-color: rgba(0, 0, 0, .05);
						}
					}

					&:hover {
						background-color: rgba(0, 0, 0, .075);
						border-bottom: 1px rgba(0, 0, 0, .05) solid;
					}
				}

				&:hover {
					background-color: rgba(0, 0, 0, .075);
					border-bottom: 1px rgba(0, 0, 0, .05) solid;
				}
			}
		}

		thead {
			background-color: #D9ECFF;
		}

		.ant-btn-link{
			font-size: 12px;
			height: 24px;
		}

		.ant-table-thead > tr >th.onbRiskTitle, .ant-table-thead > tr >th.onbLogoTitle {
			text-align: center;
			color: white;
			background: #196C93 !important;
		}

		.ant-table-thead > tr >th {
			border-spacing: 0 !important;
			margin-left:1px;
			margin-bottom:1px;
			border-right: 1px solid rgb(255, 255, 255);
			border-collapse: unset !important;

			&.column-cumplimiento {
				border-bottom: 1px solid rgb(255, 255, 255);
			}
		}


		.ant-table-thead > tr >th.onbLogoTitle {
			.image {
				width: 34px;
				height: 57px;
				// background-image: url(./logoimges.png);
				background-size: cover;
				background-repeat: no-repeat;
				background-position: center center;
			}
		}

		.ant-table {

			.ant-table-body {
				margin-left: 0px !important;
				margin-right: 0px !important;
			}

			font-size: 12px;

			// .ant-table-header-column{
			// 	margin-left:-15px;
			// }
			.item-risk-align{
				display: flex;
				flex-direction: column;
				align-items: center;
			}

			.child-risk-onb {
				border-radius: 8px;
				padding: 0px;
				text-align: center;
				vertical-align: middle;
				padding: 4px;
			}

			.child-risk-onb-circle {
			border-radius: 12px;
			height: 24px;
			width: 24px;
			line-height: 24px
			}


			.ant-table-column-sorter-inner {
				margin-top: -0.4em !important;
			}


			.edit-action {
				padding: 5px;
				width: 70px;

				&:hover {
					animation-duration: 0.5s;
					animation-fill-mode: forwards;
					animation-name: grey-fade-in;

					@keyframes grey-fade-in {
						to {
							background: rgba(0, 0, 0, .07);
							border-radius: 5px;
							cursor: pointer;
						}
					}
				}
			}

			.download-report {
			padding: 5px;
			width: 130px;

				&:hover {
					animation-duration: 0.5s;
					animation-fill-mode: forwards;
					animation-name: grey-fade-in;

					@keyframes grey-fade-in {
						to {
							background: rgba(0, 0, 0, .07);
							border-radius: 5px;
							cursor: pointer;
						}
					}
				}
			}
		}

		.ant-pagination {
			text-align: center;
			width: 100%;
		}

		.imgLogoGes{
			height: 100%;
			width: 100%;
		}
	}


	.modal-fichaCliente-onb{
		overflow-x: hidden;
	}
}

// FIN TRANSFERENCIA DE ESTILOS

//INICIO FILTER WRAPPER
.filters-wrapper {
	overflow: hidden;
	position: relative;

	&.null {
		height: 0;

		.filters-wrapper-inner {
			transform: translateY(-130px);
		}
	}

	&.show {
		animation-duration: 0.75s;
		animation-fill-mode: forwards;
		animation-name: show-parent-new-register;
		z-index: 800;

		@keyframes show-parent-new-register {
			from {
				height: 0;
			}

			to {
				height: 90px;
			}
		}

		.filters-wrapper-inner {
			animation-duration: 0.75s;
			animation-fill-mode: forwards;
			animation-name: show-child-new-register;

			@keyframes show-child-new-register {
				from {
					transform: translateY(-130px);
				}

				to {
					transform: translateY(0);
				}
			}
		}
	}

	&.hide {
		animation-duration: 0.3s;
		animation-fill-mode: forwards;
		animation-name: hide-parent-new-register;
		overflow: hidden;
		position: relative;
		z-index: 800;

		@keyframes hide-parent-new-register {
			from {
				height: 90px;
			}

			to {
				height: 0;
			}
		}

		.filters-wrapper-inner {
			animation-duration: 0.3s;
			animation-fill-mode: forwards;
			animation-name: hide-child-new-register;

			@keyframes hide-child-new-register {
				from {
					transform: translateY(0);
				}

				to {
					transform: translateY(-130px);
				}
			}
		}
	}
}
// FIN FILTER WRAPPER

.modal-cliente .image-wrapper img {
  height: 115px;
}

.subclient-dropdown-uploadclient {
  position: relative;
  //right: 20px;
  top: 0px;
  left: 0px;
  width: 100%;

  img {
    height: 20px !important;
    //float: left;
    //margin-right: 20px;
  }
}

.subclient-logo {
  //background: url('./subclient-placeholder-img.png') no-repeat;
  border-radius: 10px;
  float: left;
  height: 20px;
  margin-right: 0;
  overflow: hidden;
  position: relative;
  top: 5px;
  width: 20px;
}

.select-all-container {
    background-color: #e5f7ff;
    width: 100%;
    padding: 5px;
    line-height: 2;
	text-align: center;
	display: inline-block;
	transition: background-color 0.3s ease;
}


.select-all-container span {
    color: #0376ba;
    cursor: pointer;
	font-weight: bold;
	padding: 5px;
}

.select-all-container span:hover {
    background-color: #daecff; 
	padding: 5px; 
}

.select-all-container span:hover span {
    padding-right: 0; 
}

