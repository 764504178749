.quiebras {
  .sidebar {
    overflow-y: scroll;
    position: absolute;
    left: 0;
    top: 0;
    width: 300px;

    &::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
      background-color: transparent;
    }

    &::-webkit-scrollbar {
      width: 8px;
      background-color: #F5F5F5;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
      background-color: rgba(0, 0, 0, .1);
    }

    .sidebar-inner {
      padding: 20px;
    }
  }
}
