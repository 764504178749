.profile {
  .topbar {
    background-color: rgba(255, 255, 255, .5);
    border-bottom: 1px rgba(0, 0, 0, .15) solid;
    overflow: hidden;
    padding: 15px;
    
    .btn-collapsed-menu-wrapper{
      .ant-btn{
        left: -10px !important;
        bottom: 0px !important;
      }
    }
  }
}
