.quiebras {
  .page-content {
    background: transparent url('./bg.png') repeat-y top left;
    padding: 0;
    position: relative;

    .items {
      min-height: 690px;
      overflow: hidden;
    }

    .page-footer {
      background: transparent;
      position: absolute;
      left: 300px;
      bottom: 0;
      right: 0;
    }
  }
  .btn-actions {
    position: absolute;
    top: 28px;
    right: 34px;
    //width: 200px;

    button {
      margin: 2px;
    }
  }

  .top-quiebra-buttons {
    right: -70px;
    position: absolute;
    top: 24px;
    width: 500px;

    .ant-btn {
      float: left;
      margin-left: 10px;
      width: auto;
    }
  }

  .spinner-overlay {
    bottom: 70px;
    left: 300px;
    position: absolute;
    right: 0;
    top: 163px;

    .ant-spin {
      left: 50%;
      margin: -20px 0 0 -20px;
      position: absolute;
      top: 35%;
    }
  }
}

.subclient-dropdown-uploadclient {
  position: relative;
  //right: 20px;
  top: 0px;
  left: 0px;
  width: 100%;

  img {
    height: 20px !important;
    //float: left;
    //margin-right: 20px;
  }
}

.subclient-logo {
  //background: url('./subclient-placeholder-img.png') no-repeat;
  border-radius: 10px;
  float: left;
  height: 20px;
  margin-right: 0;
  overflow: hidden;
  position: relative;
  top: 5px;
  width: 20px;
}
