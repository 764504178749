.subclient-dropdown {
  position: absolute;
  right: 20px;
  top: 30px;

  label {
    padding-right: 10px;
  }

  .ant-select {
    width: 220px;
  }

  #subclient-dropdown {
    img {
      height: 20px !important;
      float: left;
      margin-right: 20px;
    }

    .subclient-logo {
      background: url('./subclient-placeholder-img.png') no-repeat;
      border-radius: 10px;
      float: left;
      height: 20px;
      margin-right: 0;
      overflow: hidden;
      position: relative;
      top: 5px;
      width: 20px;
    }
  }
}

.ant-select-dropdown-menu {
  .ant-select-dropdown-menu-item {
    line-height: 20px;

    img {
      border-radius: 10px;
      height: 20px !important;
      float: left;
      overflow: hidden;
      margin-right: 0;
    }
  }
}
