.search-filters {
  overflow: hidden;

  .ant-calendar-picker {
    margin-right: 10px;
  }

  .ant-col {
    .col-inner {
      //margin-bottom: 15px;
    }

    .col-inner:first-child {
      padding-right: 10px;
    }

    .col-inner:last-child {
      padding-left: 10px;
    }

    .col-col-inner.to {
      padding-left: 0;
      padding-right: 10px;
    }

    .col-col-inner.to {
      padding-left: 10px;
      padding-right: 0 !important;
    }

    .ant-btn {
      margin: 0 4px;
      margin-top: 15px;
    }

    label {
      color: white;
    }

    .ant-form-item {
      margin-bottom: 0 !important;
    }
  }
}
