#footer {
  background: rgba(0, 0, 0, .3);
  box-shadow: inset 0px 45px 28px -33px rgba(0,0,0,0.1);
  -webkit-box-shadow: inset 0px 45px 28px -33px rgba(0,0,0,0.1);
  -moz-box-shadow: inset 0px 45px 28px -33px rgba(0,0,0,0.1);
  padding: 20px 0 0;

  div.logo-wrapper {
    overflow: hidden;
    width: 400px;

    img.logo {
      height: 35px;
    }
  }

  img.language-icon {
    height: 20px;
  }

 .css-3d-text {
    font-size: 30px;
    color: #ffffff;
    font-family: "sans-serif", Gadget, sans-serif;
    text-shadow: 0px 0px 0 rgb(205,205,205),
                 0px 1px  0 rgb(155,155,155),
                 0px 2px 1px rgba(0,0,0,0.24),
                 0px 2px 1px rgba(0,0,0,0.5),
                 0px 0px 1px rgba(0,0,0,.2);
 }

 .h3-wrapper {
   h3 {
     color: #fff;
   }
 }

 .navigation {
   float: none;
   list-style-type: none;
   margin: 0;
   padding: 0;

   li {
     border-radius: 5px;
     overflow: hidden;
     padding: 7px 10px;

     a, span { color: rgba(255, 255, 255, .7) !important; }

     .circle {
       background: rgba(0, 0, 0, 0.2);
       border-radius: 10px;
       float: left;
       height: 20px;
       line-height: 17px;
       margin-right: 10px;
       text-align: center;
       width: 20px;

       .anticon {
         color: white;
         font-size: 0.7em;
         margin: 0 !important;
       }
     }

     .anticon-check {
       display: none;
       float: right !important;
       position: relative;
       top: 4px;
     }
   }
 }

 .footer-inner {
   margin: 0 auto;
   padding: 10px 0 25px;
   width: 1200px;
 }

 .footer-bottom {
   border-top: 1px rgba(255, 255, 255, .12) solid;
   color: rgba(255, 255, 255, .8);
   padding: 10px 0;
   text-align: center;
   width: 100%;
 }

 .powered {
   span {
     color: rgba(255, 255, 255, .7);
     padding-right: 10px;

     a {
       color: rgba(255, 255, 255, .7);
       text-decoration: underline;
     }
   }

   img {
     height: 20px;
   }
 }

 .col-inner {
 }

 .selector {
   list-style-type: none;
   margin: 0;
   padding: 0;

   li {
     color: rgba(255, 255, 255, .7);
     cursor: pointer;
     border-radius: 5px;
     overflow: hidden;
     padding: 7px 10px;

     &.selected {
       background: rgba(0, 0, 0, .1);

       .anticon {
         display: block !important;
       }
     }

     .theme-color {
       background: #fff;
       border-radius: 10px;
       float: left;
       height: 20px;
       margin-right: 10px;
       width: 20px;
     }

     img {
       margin-right: 10px;
     }

     a {
       color: rgba(255, 255, 255, .7);
       line-height: 18px;
     }

     .anticon {
       display: none;
       float: right;
       position: relative;
       right: 5px;
       top: 4px;
     }
   }
 }
}

#inicio #footer .navigation li#button-footer-home,
#consulta #footer .navigation li#button-footer-query,
#masivos #footer .navigation li#button-footer-batch,
#registro #footer .navigation li#button-footer-register,
#administracion #footer .navigation li#button-footer-administration {
  background: rgba(0, 0, 0, .1);

  .anticon-check {
    display: block;
  }
}
