.admin-consultas {
  .tools-area {
    border-radius: 5px;
    margin-bottom: 20px;
    padding: 20px;
  }

  .table-wrapper {
    min-height: 420px;
    position: relative;
  }

  .ant-pagination {
    top: 445px;
    position: absolute;
    text-align: center;
    width: 100%;
    z-index: 10;
  }

  .total-row {
    font-weight: bold;
  }
  .subtotal-row {
    background-color: #EEECE1;
  }
}
