.modIndexCard{

    .ant-table-small > .ant-table-content > .ant-table-body > table > .ant-table-thead > tr > th.relatedTitle {
      background-color: #ff9f9f86; /* Cambia este color al que desees */
    }

    .ant-table-small > .ant-table-content > .ant-table-body > table > .ant-table-thead > tr > th.vinculadaTitle {
      background-color: #9fa6ff86; /* Cambia este color al que desees */
    }
}
