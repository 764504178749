.tab-reports {
  background: rgba(255, 255, 255, .1);
  border-radius: 10px !important;
  border: 1px rgba(0, 0, 0, .15) solid;
  margin: 10px;
  overflow: hidden;
  -webkit-box-shadow: 0px 0px 39px -8px rgba(0,0,0,0.1);
  -moz-box-shadow: 0px 0px 39px -8px rgba(0,0,0,0.1);
  box-shadow: 0px 0px 39px -8px rgba(0,0,0,0.1);


  .bottom-button{
    margin: 10px;
    float: right;
  }

  .asignadas{
    float: right;
    padding-right: 10px;
  }

  .inner-row{
    padding: 10px;
    min-height: auto;
    overflow: auto;
    display: flex;
    align-items: center;

    .delete-filters{

    }
  }

  .inner-col-header{
    text-align: center;

  }


  .box-inner {
    -webkit-box-shadow: inset 0px 6px 10px -6px rgba(0,0,0,0.3);
    -moz-box-shadow: inset 0px 6px 10px -6px rgba(0,0,0,0.3);
    box-shadow: inset 0px 6px 10px -6px rgba(0,0,0,0.3);
    padding-bottom: 0px;
    padding-top: 10px;
    min-height: 485px;
    position: relative;


    .input-wrapper {
      padding-left: 5px;
      padding-right: 5px;

      span.label {
        display: block;
        padding-bottom: 0;
      }
    }


    .ant-row {
      padding: 5px 5px;

      .ant-col {
        .col-inner {
          background: rgba(255, 255, 255, .5);
          border: 1px rgba(0, 0, 0, .1) solid;
          border-radius: 5px;
          margin: 0 5px;
          overflow: hidden;

          .key {
            padding: 0 5px;
            background: rgba(0, 0, 0, .3);
            border-bottom: 1px rgba(0, 0, 0, .05) solid;
            border-radius: 2px;
            color: rgba(255, 255, 255, .99);
            font-size: 0.8em;


            &.collection{
              height: auto;
              line-height: 23px;
              margin: 3px;

              .inner-div{

                border-radius: 2px;
              }


            }
            .ant-checkbox-wrapper{
              font-size: 12.8px;
            }
            .ant-checkbox-inner{
              width:12px;
              height: 12px;
            }
          }



          .value {
            box-shadow: inset 0px 6px 10px -6px rgba(56, 51, 51, 0.1);
            -moz-box-shadow: inset 0px 6px 10px -6px rgba(56, 51, 51, 0.1);
            -webkit-box-shadow: inset 0px 6px 10px -6px rgba(56, 51, 51, 0.1);
            line-height: 23px;
            height: auto;
            margin: 3px 0;
            color: rgba(0, 0, 0, .5);
            font-size: 0.9em;
            padding: 0 5px;

            .ant-radio-group {
              width: 100% !important;
            }

            textarea {
              border-color: rgba(0, 0, 0, .2);
              border-radius: 5px;
              height: 100px;
              margin: 3px 0;
              width: 100%;
            }

            .value-items {
              list-style-type: none;
              margin: 0;
              padding: 0;
              width: 100%;

              li {
                border-bottom: 1px rgba(0, 0, 0, .1) solid;
                display: block;
                float: none;
                width: 100%;
                font-size: 0.7em;
                font-weight: normal;
                padding: 2px 0 4px;
                width: 100%;

                .ant-radio-wrapper {

                }

                &:last-child {
                  border-bottom: 0;
                }
              }
            }
          }
        }
      }

      &:last-child {
      }
    }



    .ant-table {
      .ant-table-thead > tr > th {
        background: rgba(0, 0, 0, .1);
        padding: 5px 0 0 10px;
      }
    }

    td{
      font-size: 0.8em;
    }

    .parameters-collection{
      &:first-child{
        padding-top: 10px;
      }
      &:last-child{
        padding-bottom: 10px;
      }

    }



  }





  .big-tabs {
    background: rgba(0, 0, 0, .5);

    ul {
      list-style-type: none;
      margin: 0;
      overflow: hidden;
      padding: 0;

      li {
        border-right: 1px rgba(0, 0, 0, .5) solid;
        color: rgba(255, 255, 255, .5);
        cursor: pointer;
        float: left;
        letter-spacing: 0.1em;
        line-height: 40px;
        height: 40px;
        text-align: center;

        &.with-1 {
          width: 100% !important;
        }

        &.with-2 {
          width: 50% !important;
        }

        &.with-3 {
          width: 33.333% !important;
        }

        &.active {
          -webkit-box-shadow: 0px 0px 34px 0px rgba(0,0,0,0.38);
          -moz-box-shadow: 0px 0px 34px 0px rgba(0,0,0,0.38);
          box-shadow: 0px 0px 34px 0px rgba(0,0,0,0.38);
          background: rgba(255, 255, 255, .7);
          color: rgba(0, 0, 0, .5);
        }

        &:last-child {
          border-right: 0;
        }
      }
    }
  }


}
