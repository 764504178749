.modal-new-release {
	box-shadow: 1px -1px 62px -13px rgba(0,0,0,0.82);
-webkit-box-shadow: 1px -1px 62px -13px rgba(0,0,0,0.82);
-moz-box-shadow: 1px -1px 62px -13px rgba(0,0,0,0.82);
position: relative;
z-index: 4000;

	background: white;
	border-radius: 10px !important;
	overflow: hidden;
	padding: 0 !important;
	position: relative;

	.ant-modal-content {
		box-shadow: none;
	}

	.preview-overlay {
		position: absolute;

		&.visible {
			bottom: 0;
			display: block;
			left: 0;
			right: 0;
			top: 0;
			z-index: 4000;
			animation-duration: 0.5s;
			animation-fill-mode: forwards;
			animation-name: fade-in-prev;

			@keyframes fade-in-prev {
				from {
					background-color: transparent;
				}
				to {
					background-color: rgba(255, 255, 255, .7);
				}
			}

			.anticon {
				left: 15px;
				position: absolute;
				top: 15px;
			}
		}

		&.no-visible {
			display: none;
		}
	}

	.ant-modal-close-x {
		color: #fff;
		font-size: 0.9em;
		height: auto;
		line-height: 1em;
		position: absolute !important;
		right: 8px !important;
		top: 9px !important;
		width: auto;
	}

	.ant-modal-body {
		padding: 0;

		div.total {
			background: rgba(0, 0, 0, 0.17);
			h4 {
				padding: 5px;
				color: #fff;
				height: 24px;
				margin: 0px;
			}
			ul.ant-pagination {
				position: absolute;
				top: 0px;
				right: 20px;
			}
		}

		div.loading {
			height: 339px;
		}

		h3 {
			background: rgba(0, 0, 0, .3);
			color: white;
			margin: 0;
			padding: 5px 0;
			text-indent: 7px;
		}

		.body-wrapper {
			padding: 0;
		}

		div.totalSelected {
			color: rgba(0, 0, 0, .5);
			h1 {
				color: rgba(0, 0, 0, .5);
				margin:10px;
				text-align: center;
				padding-top: 100px;
			}
			div.filters {
				padding-top: 20px;
				margin: auto;
  			width: 70%;

				div.ant-row {
					margin-top: 10px;
				}
			}
		}
	}

	.ant-radio-group {
		display: block;
	}

	#category-select1 {
		box-shadow: 172px 1px 36px -178px rgba(0, 0, 0, 0.3) inset;
		background: rgba(0, 0, 0, .1);
		height: 145px;
		z-index: 3;
		position: relative;
    -webkit-box-shadow: 172px 1px 36px -178px rgba(0, 0, 0, 0.3) inset;
    -moz-box-shadow: 172px 1px 36px -178px rgba(0, 0, 0, 0.3) inset;

		h2 {
			margin-bottom: 0;
		}

		img {
			height: 70px;
			opacity: 0.15;
			left: 20px;
			position: absolute;
			top: 55px;
		}

		.category-select-inner {
			box-shadow: 1px 18px 16px -13px rgba(0, 0, 0, 0.2) inset;
    -webkit-box-shadow: 1px 18px 16px -13px rgba(0, 0, 0, 0.2) inset;
    -moz-box-shadow: 1px 18px 16px -13px rgba(0, 0, 0, 0.2) inset;
			height: 111px;

			p {
				color: rgba(0, 0, 0, .5);
				line-height: 0.3em !important;
				width: 100% !important;
				margin: 0 !important;
				padding: 25px 30px 5px 105px;
				text-align: center;
			}



			.buttons-wrapper {
				padding-left: 60px;

				.ant-btn {
					background-color: rgba(0, 0, 0, .1) !important;
					border: 0;
					color: rgba(0, 0, 0, .3);
					font-size: 1.1em;
					height: 35px;
					margin: 0;
					width: 150px;

					&:first-child {
						border-bottom-left-radius: 17px;
						border-top-left-radius: 17px;
						left: 30px !important;
						margin-left: 20px;
					}

					&:last-child {
						border-bottom-right-radius: 17px;
						border-top-right-radius: 17px;
						margin-left: 35px;
					}

					&.selected {
						-webkit-box-shadow: 0px 0px 6px 1px rgba(0,0,0,0.07);
						-moz-box-shadow: 0px 0px 6px 1px rgba(0,0,0,0.07);
						box-shadow: 0px 0px 6px 1px rgba(0,0,0,0.07);
						background-color: rgba(255, 255, 255, .7) !important;
						color: rgba(0, 0, 0, .5) !important;
					}
				}
			}
		}
	}

	#category-select {
		p {
			left: 110px !important;
		}
		.ant-select {
			left: 95px !important;
		}
	}

	#category-select, #type-select {
    box-shadow: 172px 1px 36px -178px rgba(0, 0, 0, 0.3) inset;
		background: rgba(0, 0, 0, .1);
		height: 145px;
		z-index: 3;
		position: relative;
    -webkit-box-shadow: 172px 1px 36px -178px rgba(0, 0, 0, 0.3) inset;
    -moz-box-shadow: 172px 1px 36px -178px rgba(0, 0, 0, 0.3) inset;

    .ant-select {
      margin: 10px 15px;
      position: absolute;
      left: 80px;
      top: 80px;
      width: 330px;
    }

    img {
      height: 70px;
      left: 23px;
      opacity: 0.3;
      position: absolute;
      top: 55px;
    }

    p {
      color: rgba(0, 0, 0, .5);
      left: 100px;
      position: absolute;
      top: 55px;
      width: 330px;
    }

		h2 {
			box-shadow: 1px 172px 36px -178px rgba(0,0,0,0.4) inset;
			-webkit-box-shadow: 1px 172px 36px -178px rgba(0,0,0,0.4) inset;
			-moz-box-shadow: 1px 172px 36px -178px rgba(0,0,0,0.4) inset;
			margin-bottom: 0;
		}
	}

	.ant-col {
		.col-inner {
			line-height: 1em;

			.box {
				background: rgba(0, 0, 0, .1);
				border-radius: 0;
				overflow: hidden;
				margin-bottom: 0;
				padding: 0;

				h2 {
					background: rgba(0, 0, 0, .3);
					color: white;
					font-size: 1em;
					margin-bottom: 10px;
					padding: 10px 0;
					text-indent: 10px;
				}

				&#subject-select {
					h2 {
						margin-bottom: 0;
					}
					.subject-select-inner {
						box-shadow: 1px 172px 15px -172px rgba(0,0,0,0.3) inset;
						-webkit-box-shadow: 1px 172px 15px -172px rgba(0,0,0,0.3) inset;
						-moz-box-shadow: 1px 172px 15px -172px rgba(0,0,0,0.3) inset;
						padding: 10px;
					}

					.ant-input {

					}
				}

				&#periodicity-select {
					box-shadow: 172px 1px 36px -178px rgba(0,0,0,0.3) inset;
					-webkit-box-shadow: 172px 1px 36px -178px rgba(0,0,0,0.3) inset;
					-moz-box-shadow: 172px 1px 36px -178px rgba(0,0,0,0.3) inset;

					h2 {
						box-shadow: 1px 172px 36px -178px rgba(0,0,0,0.4) inset;
						-webkit-box-shadow: 1px 172px 36px -178px rgba(0,0,0,0.4) inset;
						-moz-box-shadow: 1px 172px 36px -178px rgba(0,0,0,0.4) inset;
						margin-bottom: 0;
					}
					.periodicity-select-inner {
						box-shadow: 1px 172px 15px -172px rgba(0,0,0,0.4) inset;
						-webkit-box-shadow: 1px 172px 15px -172px rgba(0,0,0,0.4) inset;
						-moz-box-shadow: 1px 172px 15px -172px rgba(0,0,0,0.4) inset;
						height: 111px;

						.periodicity-left {
							background: rgba(0, 0, 0, .05);
							height: 111px;
							float: left;
							padding: 15px 20px;
							position: relative;

							img {
								height: 55px;
								opacity: 0.3;
								left: 0;
								position: relative;
								top: 12px;
							}

							.explainer {
								line-height: 1.3em;
								position: absolute;
								top: 15px;
								right: 15px;
								width: 310px;
							}

							.options {
								position: absolute;
								right: 37px;
								bottom: 15px;

								ul {
									list-style-type: none;
									margin: 0;
									overflow: hidden;
									padding: 0;
									width: 90px;

									li {
										color: rgba(0, 0, 0, .4);
										display: inline-block;
										font-size: 0.9em;
                    line-height: 0.5em;
										margin-bottom: 0;
										margin-right: 0;

										&:last-child {
											margin-right: 0;
										}

										.ant-radio-wrapper {
											margin-right: 3px;
										}
									}
								}
							}
						}

						.periodicity-right {
							background: rgba(0, 0, 0, .10);
							box-shadow: 172px 1px 36px -178px rgba(0,0,0,0.3) inset;
							-webkit-box-shadow: 172px 1px 36px -178px rgba(0,0,0,0.3) inset;
							-moz-box-shadow: 172px 1px 36px -178px rgba(0,0,0,0.3) inset;
							float: left;
							height: 111px;

							.top-bar {
								height: 60px;
								overflow: hidden;
								padding: 13px 20px 15px 15px;

								img {
									float: left;
									height: 33px;
									opacity: 0.7;
									margin-right: 12px;
								}

								p {
									color: rgba(0, 0, 0, .4);
									font-size: 0.9em;
									line-height: 1.5em;
									position: relative;
									top: 0;
								}
							}

							.bottom-bar {
								background: rgba(0, 0, 0, .05);
								box-shadow: 1px 172px 15px -172px rgba(0,0,0,0.3) inset;
								-webkit-box-shadow: 1px 172px 15px -172px rgba(0,0,0,0.3) inset;
								-moz-box-shadow: 1px 172px 15px -172px rgba(0,0,0,0.3) inset;
								height: 51px;
								padding: 8px 15px 0 17px;

								.ant-calendar-picker {
									position: relative;
									top: 6px;
								}
							}
						}
					}
				}

				&#preview {
					margin-right: 10px;

					.preview-inner {
						background: rgba(255, 255, 255, .7);
						border-radius: 5px;
						height: 200px;
						margin: 15px;
					}
				}

/*
				&#type-select {
					background: rgba(0, 0, 0, .1);
					height: 145px;
					z-index: 3;
					position: relative;

					h2 {
						box-shadow: 1px 172px 36px -178px rgba(0,0,0,0.4) inset;
						-webkit-box-shadow: 1px 172px 36px -178px rgba(0,0,0,0.4) inset;
						-moz-box-shadow: 1px 172px 36px -178px rgba(0,0,0,0.4) inset;
						margin-bottom: 0;
					}

					& > div {

					}

						&:last-child {
							.col-inner {
							border-right: 0 !important;
							}
						}

//termina col-inner
						&:last-child {
							padding: 0;
						}

						&.active {
							.col-inner {
								animation-duration: 0.75s;
								animation-fill-mode: forwards;
								animation-name: hover-fade-in;

								@keyframes hover-fade-in {
									to {
										background-color: rgba(255, 255, 255, .3);
									}
								}
							}
						}

						&.selected {
							.option, .check-inner {
								background-color: rgba(50, 132, 44, .9) !important;

								.anticon {
									color: white !important;
								}
							}

							.col-inner {
								box-shadow: 0px 0px 25px 0px rgba(0,0,0,0.15);
								-webkit-box-shadow: 0px 0px 25px 0px rgba(0,0,0,0.15);
								-moz-box-shadow: 0px 0px 25px 0px rgba(0,0,0,0.15);
								animation-duration: 1s;
								animation-fill-mode: forwards;
								animation-name: selected-hover-fade-in;

								@keyframes selected-hover-fade-in {
									to {
										background-color: rgba(255, 255, 255, .7);
									}
								}
							}
						}
					}
*/
				&#clients-unselected-list {
					height: 457px;
					position: relative;

					.add-button {
						background: rgba(255, 255, 255, .5);
						bottom: 24px;
						height: 40px;
						width: 100% !important;
						z-index: 2000;

						.ant-btn {
							position: relative;
							left: 9px;
							top: 5px;
							width: 520px;
						}
					}


					.autocomplete-wrapper {
						position: absolute;
						right: 6px;
						top: 7px;
						width: 200px;
					}

					h2 {
						-webkit-box-shadow: inset 0px 4px 12px 0px rgba(0,0,0,0.15);
						-moz-box-shadow: inset 0px 4px 12px 0px rgba(0,0,0,0.15);
						box-shadow: inset 0px 4px 12px 0px rgba(0,0,0,0.15);
						margin: 0;
					}

					.filter-row {
						background: rgba(0, 0, 0, .17);
						border-bottom: 2px rgba(0, 0, 0, .05) solid;
						color: white;
						height: 42px;
						padding: 8px 20px 8px 10px;
						-webkit-box-shadow: 0px 5px 10px -1px rgba(0,0,0,0.1);
						-moz-box-shadow: 0px 5px 10px -1px rgba(0,0,0,0.1);
						box-shadow: 0px 5px 10px -1px rgba(0,0,0,0.1);
					}

					.input-wrapper {
						display: block;
						margin: 15px auto 15px;
						width: 870px;
					}

						.col-inner {
							background: transparent;
							margin-left: 0 !important;
							margin-right: 0 !important;
							position: relative;
						}

					.ant-spin {
						left: 50%;
						margin-left: -10px;
						position: absolute;
						top: 50%;
					}

					.ant-col:first-child {
						h4, .col-inner {
							margin-right: 5px;
						}
					}

					.ant-col:last-child {
						h4, .col-inner {
							margin-left: 5px;
						}
					}

					.arrow {
						background: #999;
						border-radius: 25px;
						display: none;
						height: 50px;
						left: 420px;
						position: absolute;
						top: 350px;
						width: 50px;
						z-index: 1000;

						.anticon {
							color: white;
							font-size: 1.5em;
							left: 50%;
							margin: -10px 0 0 -10px;
							position: absolute;
							top: 50%;
						}
					}

					.items-wrapper {
						height: 315px;
						overflow-y: scroll;

						.items-collection {
							list-style-type: none;
							padding: 0;
							margin: 0;

							.item-dest {
								border-bottom: 1px rgba(0, 0, 0, .05) solid;
								color: rgba(0, 0, 0, .5);
								padding: 6px 0 8px 10px;
								text-overflow: ellipsis;
								overflow: hidden;
								white-space: nowrap;

								&:nth-child(odd) {
									background-color: rgba(0, 0, 0, .02);
								}

								.ant-col {
									overflow: hidden;
									text-overflow: ellipsis;
									white-space: nowrap;
								}
							}
						}
					}
				}
			}
		}

		&:last-child {
			.col-inner {
				padding-right: 0;
			}
		}
	}

	.send-wrapper {
		background: rgba(0, 0, 0, .3);
		box-shadow: 1px 172px 36px -178px rgba(0, 0, 0, 0.4) inset;
		-webkit-box-shadow: 1px 172px 36px -178px rgba(0, 0, 0, 0.4) inset;
		-moz-box-shadow: 1px 172px 36px -178px rgba(0, 0, 0, 0.4) inset;
		padding: 0;

		#send {
			border-top-left-radius: 0;
			border-top-right-radius: 0;
			height: 40px;
			width: 100%;
		}
	}

  .recipients, .add-button.second {
    -webkit-box-shadow: 172px 1px 36px -178px rgba(0, 0, 0, 0.3) inset;
    -moz-box-shadow: 172px 1px 36px -178px rgba(0, 0, 0, 0.3) inset;
    box-shadow: 172px 1px 36px -178px rgba(0, 0, 0, 0.3) inset;
  }
}

.tooltip-h3, .tooltip-p {
	color: rgba(255, 255, 255, .7);
	margin: 0;
	padding: 0;
}

.tooltip-h3 {
	border-bottom: 1px rgba(255, 255, 255, .2) solid;
	font-size: 1em;
	margin-bottom: 5px;
	padding-bottom: 5px;
}

.tooltip-p {
	font-size: 0.9em;
}
