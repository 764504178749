.historyCDIModal {

    .title {
        font-weight: bold;
        text-align: center;
        padding: 0px 20px 10px 20px;
        
        // border-bottom: 1px solid lightgray;
    }

    .data-item {
        // border: 1px solid lightgray;
        border: 1px solid rgb(158, 158, 158);
        border-radius: 5px;
        padding: 20px;
        text-align: center;
        margin-top: 5px;
        margin-bottom: 5px;
       // margin: 5px;

        .desc-title{
            font-weight: bold;
            height: 100px;
            line-height: 100px;
            text-align: center;
        }

        .comments {
            margin: 10px;
            text-align: start;
            border: 1px solid #d9d9d9;
            border-radius: 4px;
            background-color: #fff;
            padding: 5px;
            min-height: 30px;
            font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI',Roboto, 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
            line-height: 25px;
            white-space: pre-line;                 /* CSS3 browsers  */
            white-space: -moz-pre-line !important; /* 1999+ Mozilla  */
            white-space: -pre-line;                /* Opera 4 thru 6 */
            white-space: -o-pre-line;              /* Opera 7 and up */
            word-wrap: break-word;               /* IE 5.5+ and up */
        }
    }
}
