.site-layout-content {
  background: rgb(141, 139, 139);
  padding: 24px;
  min-height: 280px;
}
#components-layout-demo-top .logo {
  width: 120px;
  height: 31px;
  background: rgba(255, 255, 255, 0.2);
  margin: 16px 24px 16px 0;
  float: left;
}

.form-content.form-validate-messages {
}

.form-content-kyc {
  &.form-validate-messages {
     div.has-error div.ant-form-explain {
       display: none;
     }
  }

  &.pdf {
    .radio-item-flat {
      margin-left: 25px;
    }

    .ant-checkbox-checked .ant-checkbox-inner:after {
      top: 6px;
      left: 3px;
    }
  }

  .missing-registers{
    color: #f5222d;
    padding-bottom: 5px;
    font-size: 12px;
  }

  .form-form {

    border-style: solid;
    border-width: 2px;
    padding: 20px;

    hr {
      margin: 2px 0px;
    }

    .ant-checkbox-group.has-title {

      &::before {
        content: '';
        position: absolute;
        width: 51%;
        height: 0px; // suit your need
        background: rgba(0,0,0,.4); // suit your need
        top: -35px;
      }
    }

    .radio-button-title {
      margin-top: 6px;
      &::after {
        content: '';
        position: absolute;
        width: 100%;
        height: 1px; // suit your need
        background: rgba(0,0,0,.4); // suit your need
        top: 27px;
      }
    }

    .radio-switch{
      display: block;
      height: 30px;
      line-height: 30px;
    }


    .hr-col{
      text-align: center;
    }

    .ant-table-column-title {
      color: rgba(0, 0, 0, 0.65)
    }

    .ant-table-wrapper {
      margin-top: 10px;
      margin-bottom: 30px;
      background-color: white;
    }


    .summary {
      .introduction-item{
        margin-bottom: 0px;
        display: inline-block;
        vertical-align: middle;
        width: 200px;

        input {
          background-color: #fff;
        }
      }
    }

    .tooltip-form {
      font-weight: bold;
      font-style: italic;
    }

    .ant-form-item-label {
      line-height: 0px;     //separation with input box
      padding-left: 10px;
    }

    .ant-form-item-label > label {
      color: black;
    }

    .ant-form-item {
      margin-bottom: 12px;

      .ant-form-explain {
        font-size: smaller;
        position: absolute;
        color: red !important;
      }
    }

    .ant-input-number {
      width: 100%;
    }

    .leftLabel{
      .ant-form-item-label{
        padding-top: 12px;
        text-align: left;
        line-height: 14px;
      }
      .ant-form-item{
        margin-bottom: 0px;

      }

    }

    .topLabel{
      .ant-form-item-label{
        line-height: 16px;
      }
    }

    .clean-header{
      margin-bottom: 0px;
    }

    .submitResponsible{
      text-align: center;
    }

    .submitTrabajador{
      text-align: center;
    }




    .button-row{
      padding:20px ;
    }

    .button-col{
      margin-top: 25px;
      text-align: center;
    }

    .subheader{
      background-color: rgba(0,0,0,.2);
      padding-left: 10px;
      padding-top: 3px;
      padding-bottom: 3px;
      margin-top: 10px;
      margin-bottom: 10px;
    }

    .content{
      padding: 0px;
      &.observaciones {
        border-style: solid;
        border-width: 1px;
        border-color: rgba(0, 0, 0, 0.4);
      }

      .inner-row {
        padding: 2px;
      }
    }


    .summary {
      margin-top: 0px;
      margin-bottom: 0px;
      text-align: justify;
      padding: 10px;
      color: black;
    }

    .form-radio-center{
      margin-top: 20px;
      text-align: center;
      vertical-align: middle;

      .col-label{
      padding-top: 9px;
      margin-bottom: 0px;
      text-align: left;
      }
    }

    .form-radio-left{
      margin-top: 20px;
      text-align: left;
    }

    .subsection-title{
      font-weight: 700;
    }

    .ant-radio + * {
      color: black;
    }

    .ant-checkbox + span {
      color: black;
    }

    .question-title{
      font-weight: 600;
    }

    .check-grid{
      .ant-checkbox-wrapper{
          padding: 10px;
      }

      // .ant-col-6{
      //   margin-left: 773px;
      //   margin-top: -84px;
      // }
    }
  }
}
