.admin-groups {
  .tools-area {
    border-radius: 5px;
    margin-bottom: 20px;
    padding: 20px;
    text-align: right;
  }

  h3.title-section {
    background-color: rgba(0, 0, 0, 0.3);
    margin: 10px 0px 0px 0px;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    color: rgba(255, 255, 255, 1);
    font-weight: bold;
    padding: 7px 0;
    text-align: center;
  }
  .groupInput {
    margin-bottom: 3px;
  }
}
