.modIndexCard{
  .titleRow {
    margin-top: 0px !important;
  }

  @keyframes append-animate-more-info {
      from {
        transform: scaleY(0);
        opacity: 0;
      }
      to {
        transform: scaleY(1);
        opacity: 1;	
      }
  }

  .div-more-info {
      transform-origin: 50% 0;
      animation: append-animate-more-info .2s linear;
  }

  .ant-row {
          margin-top: 30px;
  }

  .status-box{
      border: 1px solid #d9d9d9;
      border-radius: 3px;
      border-width: thin;
      padding: 0px 30px 20px 30px;
      margin-top: 20px;
  }

  .button-box-index{
      float: right;

  }
}
