#pep-sanctions {
  h3 {
    background: rgba(0, 0, 0, .3);
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    color: #fff;
    font-weight: bold;
    margin: 0;
    padding: 10px 0;
    text-align: center;
  }

  .table-wrapper {
    margin-bottom: 15px;

    .ant-table {
      border: 1px #ddd solid;
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
    }

    &.titular {
      h3 {
        background: rgba(0, 0, 0, .3);
      }
    }

    &.relations, &.relations-pep {
      h3 {
        background: rgba(0, 0, 0, .3);
      }
    }

    &.relations-pep {
      thead.ant-table-thead {
        display: none;
      }

      tr.ant-table-row td {
        vertical-align: top;
      }

      tr.ant-table-row:not(:hover):nth-child(2n) td {
          background-color: #fbfbfb;
      }

      tr div.rutName {
        text-align: center;
      }
      tr div.title {
        color: rgba(0, 0, 0, .85);
        font-weight: 500;
      }
    }
  }
}

.pep-tab-content {
  padding: 15px;
}
