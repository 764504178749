.inside-compliance{
  .inside-alert-management-section{
    
      .ant-progress-inner{
        border-radius: 9px;
        .ant-progress-bg{
          height: 18px !important;
          border-radius: 9px;
        }

        .ant-progress-success-bg{
          height: 18px !important;
          border-radius: 9px;
        }
      }
  }
}