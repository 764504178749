.onboarding {
	.page-content {
		padding-right: 0;
	}
	.content {
		.content-inner {
			position: relative;
		}
	}
}
