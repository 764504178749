.modal-cliente {
  div.plantilla {
      float: left;
  }

  div.ant-modal-body {
    padding: 5px 15px 15px 15px;

    h4 {
      background-color: #EEEEEE;
      padding: 5px;
    }
  }
  div.prop-div {
    div.ant-col:last-child {
      padding-left: 18px;
    }
  }
  input:read-only {
    background-color: #f5f9ff;
  }
  div.select-readonly div.ant-select-selection {
    background-color: #f5f9ff;
  }

  @media(max-width: 500px) {
    .ant-radio-group {
      width: 90%;
    }
  }
  .ant-form-item {
      margin-bottom: 0px;
      .ant-form-explain {
        display: none;
      }
  }
  .fecUpdate {
    display:inline-block;
    height: 30px;
    width: 100%;
    line-height: initial;
    border: 1px solid rgba(0,0,0,.2);
    padding: 3px 10px 3px 10px;
    background-color: rgba(245,245,245,0.3);
    border-radius: 3px;
  }
  .btn-add-replegal {
    float: right;
    margin: 10px 25px 10px 10px;
  }

  .tbl-related {
    table {
      border-collapse: collapse;

      td {
        vertical-align: top;
      }
    }

    .dni .error {
        display: none;
        font-size: 10px;
        color: red;
    }

    tr.no-valid {
      td .ant-select .ant-select-selection, td .ant-input {
          background-color: rgba(0,0,0,.1);
      }

      .dni {
        div.error {
          display: block;
        }

        input {
          border: 1px solid rgba(255,0,0,.5);
          background-color: rgba(255,0,0,.1);
        }
      }
    }
  }
}
