.person-card-query-risk {
  background-color: rgba(0, 0, 0, .04);
  background-image: url('./card-glow.png');
  background-position: top left;
  background-repeat: no-repeat;
  border-radius: 5px;
  border-right: 35px rgba(0, 0, 0, .5) solid;
  border-bottom: rgba(0, 0, 0, .05) 1px solid;
  border-left: rgba(0, 0, 0, .05) 1px solid;
  box-shadow: 0px 3px 6px 0px rgba(0,0,0,0.04);
  margin: 0 0 10px;
  position: relative;
  -moz-box-shadow: 0px 3px 6px 0px rgba(0,0,0,0.04);
  -webkit-box-shadow: 0px 3px 6px 0px rgba(0,0,0,0.04);
  -webkit-animation-fill-mode: forwards;

  &.has-click {
    cursor: pointer;
  }

  &:last-child {
    margin-bottom: 0;
  }

  &.risky {
    background-color: rgba(220, 190, 142, .3) !important;
    border-bottom: rgba(0, 0, 0, .05) 1px solid !important;
    border-color: rgba(194, 85, 43, .8);
    border-left: rgba(0, 0, 0, .05) 1px solid !important;

    .person-info strong {
      color: rgba(194, 85, 43, .9) !important;
    }
  }

  &.normal {
    //border-color: #7d8d9d;
  }

  &:hover {
    animation-duration: 0.2s;
    animation-name: person-card-mouseover;
    animation-fill-mode: forwards;
    -webkit-animation-fill-mode: forwards;
  }

  .ant-row {
    .ant-col {
      height: 130px;
      animation-duration: 0.6s;
      animation-fill-mode: forwards;
      animation-name: person-card-col-enters;

      .col-inner {
        align-items: center;
        //border: 1px #00f solid;
        display: flex;
        height: inherit;

        &.person-info {
          padding-left: 30px;

          .person-info-wrapper {
            align-items: center;
            display: flex;

            .person-avatar {
              background: rgba(0, 0, 0, .1);
              mix-blend-mode: multiply;
              border-radius: 25px;
              box-shadow: 0px 3px 1px 0px rgba(0,0,0,0.04);
              flex-shrink: 0;
              float: left;
              height: 50px;
              overflow: hidden;
              margin-right: 15px;
              width: 50px !important;
              -moz-box-shadow: 0px 3px 1px 0px rgba(0,0,0,0.04);
              -webkit-box-shadow: 0px 3px 1px 0px rgba(0,0,0,0.04);

              .icon-wrapper {
                height: 50px;
                line-height: 47px;
                opacity: 0.5;
                text-align: center;
                width: 50px;

                img {
                  height: 25px;
                }
              }
            }

            .person-name-rut {
              color: rgba(0, 0, 0, .5);
              float: left;

              strong {
                display: block;
                padding-right: 20px;
                text-transform: capitalize;
              }

              .person-rut {
                font-size: 0.9em;
              }
            }
          }
        }

        &.partners-controllers {
          justify-content: center;
          h3 {
            color: rgba(0, 0, 0, .5) !important;
            font-size: 1em;
            font-weight: bold;
            margin: 0 0 5px;
            padding-left: 20px;
          }

          .owner {
            color: rgba(0, 0, 0, .5);
            display: block;
            font-size: 0.9em;
            line-height: 1.7em;
            overflow: hidden;
            padding-left: 20px;
            text-overflow: ellipsis;
            text-transform: capitalize;
            white-space: nowrap;
            width: 220px;

            .wraperPercent {
              width: 38px;
              float: left;
            }
          }


          small {
            color: rgba(0, 0, 0, .5);
            display: block;
            font-size: 0.9em;
            padding-top: 8px;
          }

          .ant-badge {
            line-height: 0.6em;
          }

          .ant-badge-count {
            font-size: 0.7em !important;
            font-weight: bold;
          }

          .ant-badge-multiple-words {
            padding: 0 6px;
          }

          .not-available {
            color: rgba(0, 0, 0, .5);
            font-size: 0.9;
            margin: 0 auto;
          }

          .skeleton {
            .skeleton-inner {
              padding-left: 20px;

              h3 {
                margin-top: 5px;
              }

              .ant-skeleton {
                width: 200px;

                li {
                  background: #ddd;
                  border-radius: 5px;
                  height: 10px;
                  margin-top: 7px;
                }
              }
            }
          }
        }

        &.compliance {
          .compliance-wrapper {
            padding: 15px 2px 0;
            width: 100%;

            h4 {
              background: rgba(0, 0, 0, .07);
              border-radius: 10px;
              color: #2f3c49;
              opacity: 0.5;
              margin: 0;
              padding: 0 0 2px;
              text-align: center;
              width: 100%;
            }

            ul {
              list-style: none;
              overflow: hidden;
              padding: 10px 0;

              li {
                border-radius: 3px;
                float: left;
                margin: 0;
                padding: 5px 0;
                width: 33%;

                .compliance-group-title {
                  color: rgba(0, 0, 0, .5);
                  // mix-blend-mode: difference;
                  display: block;
                  line-height: 1em;
                  margin-bottom: 10px;
                  padding: 0 5px;
                  text-align: center;
                }

                .compliance-group-circle {
                  border-radius: 20px;
                  box-shadow: 0px 3px 1px 0px rgba(0,0,0,0.08);
                  font-size: 1.2em;
                  height: 40px;
                  line-height: 40px;
                  margin: 0 auto;
                  text-align: center;
                  width: 40px;
                  -moz-box-shadow: 0px 3px 1px 0px rgba(0,0,0,0.1);
                  -webkit-box-shadow: 0px 3px 1px 0px rgba(0,0,0,0.08);

                  &.disabled {
                    background: rgba(0, 0, 0, .07);
                    color: #fff;
                    font-size: 1.2em;
                  }
                }
              }
            }
          }
        }

        &.relevance {
          justify-content: center;
          padding-left: 30px;

          .relevance-wrapper {
            span {
              color: rgba(0, 0, 0, .5);
              float: left;
              padding-right: 15px;
              position: relative;
              top: 2px;
            }

            .bar-bg {
              background: rgba(0, 0, 0, .05);
              border-radius: 7px;
              box-shadow: 0px 3px 1px 0px rgba(0,0,0,0.027);
              float: right;
              margin-top: 6px;
              overflow: hidden;
              width: 100px;
              -moz-box-shadow: 0px 3px 1px 0px rgba(0,0,0,0.027);
              -webkit-box-shadow: 0px 3px 1px 0px rgba(0,0,0,0.027);


              .bar {
                box-shadow: 13px 0px 20px 0px rgba(0,0,0,0.07);
                float: left;
                height: 8px;
                -webkit-box-shadow: 13px 0px 20px 0px rgba(0,0,0,0.07);
                -moz-box-shadow: 13px 0px 20px 0px rgba(0,0,0,0.07);
              }
            }
          }

          .anticon {
            color: #fff;
            font-weight: bold;
            left: 50px;
            position: relative;
            z-index: 2;
          }
        }
      }
    }
  }
}

@keyframes person-card-enters {
  from {
    position: relative;
    top: -10px;
  }

  to {
    position: relative;
    top: 0;
  }
}

@keyframes person-card-col-enters {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes person-card-mouseover {
  from {
    left: 0;
  }

  to {
    position: relative;
    left: 5px;
  }
}
