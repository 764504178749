
    .alert-rules-section{
        padding: 10px;

        .title-section{
            margin-top: 10px;
        }

        .child-risk {
            border-radius: 12px;
            cursor: default;
            height: 24px;
            float: left;
            line-height: 24px;
            text-indent: 8px;
            width: 24px;
        }

        .subtitle-section{
            background-color: #0A2D44;
            margin-top: 10px;
            border-radius: 7px;
            padding: 5px 0px 5px 12px;
            color:white;
            a {
                color: unset;
            }
        }

        .title-table-section{
            background-color: #0a2d4491;
            border-radius: 7px;
            padding: 5px 0px 5px 10px;
            color:white;
            a {
                color: unset;
            }
        }

        .body-section-wrapper{
            padding: 0px 20px 0px 20px;
        }

        .mon-falses-positives-block{
            padding: 20px;
        }

        .block .block-title{
            background: white;

            h3 {
                color: black;
                margin: 2px 0px 0px -42px;
            }
        }

        .rules-cards-wrapper{
            padding: 10px 0px;

            .ant-col {
                padding: unset;
            }

            .ant-card {
                height: 100%;

                .ant-card-head {
                    min-height: 36px;
                    padding: 0px 14px;
                    .ant-card-head-title {
                    padding: 8px 0;
                    }
                }
                .ant-card-body {
                    padding: 12px;
                }

                .data {
                    .ant-col {
                    padding: 2px;
                    }
                }
            }
        }
    }
