#navigation-menu {
  flex: 1;
  height: 50px;
  display: block;

  ul.navigation {
    max-width: calc(100vw - 280px);
    background: transparent;
    float: right;
    //max-width: calc(100vw - 287px);

    .ant-menu-horizontal {
      border-bottom: 0;
    }

    .ant-menu-horizontal > .ant-menu-item, .ant-menu-horizontal > .ant-menu-submenu {
      border-bottom: 0;
      position: relative;
      top: 0;
      vertical-align: top;
    }

    .anticon {
      margin-right: 6px !important;
    }
  }
}