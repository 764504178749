.tab-declarations {
	padding: 0px 10px;

	.ant-row {
		margin-bottom: 10px;

		button {
			width: 450px;
			text-align: left;
		}
	}

	.ant-table {
		font-size: 0.9em;
		min-height: 484px;
	}

	thead {
		-webkit-box-shadow:0px 3px 6px 0px rgba(0, 0, 0, .1) inset;
		-moz-box-shadow:0px 3px 6px 0px rgba(0, 0, 0, .1) inset;
		box-shadow:0px 3px 6px 0px rgba(0, 0, 0, .1) inset;

		th {
			background: rgba(0, 0, 0, .3) !important;
			border-radius: 0 !important;
			color: white;
			padding: 7px 0 !important;
			text-indent: 8px;
		}
	}

	td {
		padding: 4px 4px !important;
	}

	button {
		width: 90%;
		font-size: 0.8em;
		background-color: rgba(0, 0, 0, .3) !important;
	}
}
