.profile-container {
  background: transparent url('./bg.png') repeat-y top left;
  border-radius: 5px;
  width: 100% !important;
  -webkit-box-shadow: 0px 0px 5px -1px rgba(0,0,0,0.25);
  -moz-box-shadow: 0px 0px 5px -1px rgba(0,0,0,0.25);
  box-shadow: 0px 0px 5px -1px rgba(0,0,0,0.25);
  position: relative;

  &.is-transition {
    animation-duration: 0.2s;
    animation-fill-mode: forwards;
    animation-name: profile-enter-animation;
    left: 100%;
    position: absolute;
    top: 103px;
    -webkit-animation-duration: 0.2s;
    -webkit-animation-fill-mode: forwards;
    -webkit-animation-name: profile-enter-animation;
  }

  &.collapsed-true {
    padding-right: 300px;
    animation-name: sideMenuAnimationLeft;
    animation-duration: 1s;
  }

  &.collapsed-false {
    padding-right: 0px;
    animation-name: sideMenuAnimationRight;
    animation-duration: 1s;
  }

  @keyframes sideMenuAnimationLeft {
    0% {padding-right: 0px;}
    100% {padding-right: 300px;}
  }

  @keyframes sideMenuAnimationRight {
    0% {padding-right: 300px;}
    100% {padding-right: 0px;}
  }

}

@keyframes profile-enter-animation {
  from {
    left: 100%;
  }

  to {
    left: 5px;
  }
}
