.stakeholder {
	.adv-tabs-filters{
		// background-color: rgba(0, 0, 0, .1) !important;
		//background-color: #898888 !important;
		box-shadow: 0px 8px 17px 0px rgba(0,0,0,0.06);
		position: relative;
	  //margin-bottom: 20px;
	  //margin-right: 20px;
		-webkit-box-shadow: 0px 8px 17px 0px rgba(0,0,0,0.06);
		-moz-box-shadow: 0px 8px 17px 0px rgba(0,0,0,0.06);


		.ant-tabs-tabpane>.ant-row{
			margin-bottom: 10px;
		}

		.ant-tabs-bar{
			margin-bottom: 10px;
		}



		.ant-tabs-nav-scroll{
			background: white;
		}

		.ant-tabs-tab{
			background: rgba(0, 0, 0, .0) !important;
		}

		.btn-clear {
			width: 30px;
			position: absolute;
			top: 5px;
			right: 10px;
			background-color: rgba(0, 0, 0, 0.3) !important;
			font-size: 0.8em;
			z-index:1000;
		}

		.filters-inner {
			box-shadow: inset 0px 10px 18px -10px rgba(0,0,0,0.25);
			//padding: 10px 15px 7px;
			-webkit-box-shadow: inset 0px 10px 18px -10px rgba(0,0,0,0.25);
			-moz-box-shadow: inset 0px 10px 18px -10px rgba(0,0,0,0.25);
			//height: 100px;
		}

		li.submenu-item-filter {
			width: 150px;
		}

		.ant-form-item {
			margin-bottom: 0;
		}

	  .ant-input-suffix {
	    display: none;
	  }

		.ant-row {
			&:nth-child(2) {
				// margin-bottom: 5px;
			}

			.ant-col {
				.col-inner {
					padding: 0 5px 0 5px;

					label {
						color: rgba(255, 255, 255, .7);
						display: block;
						line-height: 1em;
						margin-bottom: 0;
					}
				}

				&:first-child {
					.col-inner {
						padding-left: 0;
					}
				}

				&:last-child {
					.col-inner {
						padding-right: 0;
					}
				}
			}
		}

		.buttons {
			box-shadow: inset 0px 10px 18px -10px rgba(0,0,0,0.25);
			padding: 0 0 10px 0;
			text-align: center;
			-webkit-box-shadow: inset 0px 10px 18px -10px rgba(0,0,0,0.25);
			-moz-box-shadow: inset 0px 10px 18px -10px rgba(0,0,0,0.25);

	    .ant-row {
	      margin: 0 auto;
	      width: 600px;
	    }

			.ant-btn {
				background-color: rgba(255, 255, 255, .1) !important;
	      border: 0;
	      border-top: 1px rgba(0, 0, 0, .1) solid !important;
	      border-radius: 0;
	      font-size: 0.9em;
				height: 27px;
				margin: 7px 0 0;
				text-shadow: 0px 1px 3px rgba(0,1,0,0.1);
				width: 250px;

	      &:first-child {
	        border-bottom-left-radius: 3px !important;
	        border-top-left-radius: 3px !important;
	        border-right: 1px rgba(0, 0, 0, .3) solid !important;
	      }

	      &:last-child {
	        border-top-right-radius: 3px !important;
	        border-bottom-right-radius: 3px !important;
	      }

				&:hover {
					background-color: rgba(255, 255, 255, .2) !important;
				}
			}
		}
	}
}

.subitem-filter-monitor-registro .ant-menu-submenu-title {
	padding: 0px 5px;
}

li.item-filter-monitor-registro {
	padding-left: 5px !important;
	height: 45px !important;
}
div.submenu-monitor-registro-popup-filter {
	width: 912px;

	input, div.ant-select-selection {
		font-size: smaller;
	}
}
