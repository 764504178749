.stakeholder {
  .portal-alert-manager {

    .save-button {
      background: green !important;
      border: 0 !important;
      width: 100%;
    }

    .rules-items {
      background: rgba(0, 0, 0, .1);
      overflow: hidden;
      padding: 5px;
      margin: 0 0 10px 0;
      padding-left: 10px !important;
      border-radius: 5px !important;
      width: 100%;
  
      .subitem {
        float: right !important;
        width: 60%;
  
        &::before {
          // content: "o";
          padding-right: 10px;
          font-size: 0.7em;
          width: 1em;
          position: relative;
          top: -2px;
          color: rgba(0, 0, 0, .3);
        }
      }
    }

    .page-content {
      background-color: rgba(255, 255, 255, 0.5);
      background-image: none;
    }

    textarea {

      // -webkit-box-shadow: none;
      // -moz-box-shadow: none;
      // box-shadow: none;

      resize: none;
    }

    .block {
      background: linear-gradient(180deg, rgba(245, 245, 245, .7) 0%, rgba(250, 250, 250, .7) 50%, rgba(255, 255, 255, .7) 100%);
      border: 1px rgba(0, 0, 0, .1) solid;
      border-radius: 5px;
      margin-bottom: 20px;
      min-height: 40px;
      overflow: hidden;

      .block-title {
        background: rgba(0, 0, 0, .3);
        overflow: hidden;
        padding: 7px 12px;
        position: relative;
        z-index: 2;

        & > .anticon {
          color: rgba(255, 255, 255, .7);
          left: 10px;
          position: absolute;
          top: 12px;
        }

        h3 {
          color: rgba(255, 255, 255, .7);
          float: left;
          margin: 0;
          text-indent: 20px;
        }

        .ant-switch {
          position: absolute;
          right: 10px;
          top: 11px;
        }
      }

      .block-content {
        animation-duration: 0.5s;
        animation-fill-mode: forwards;

        &.show {
          animation-name: show;

          @keyframes show {
            from {
              margin-top: -4000px;
            }

            to {
              margin-top: 0;
            }
          }
        }

        &.hide {
          animation-name: hide;

          @keyframes hide {
            from {
              margin-top: 0;
            }

            to {
              margin-top: -4000px;
            }
          }
        }

        .ant-col {
          color: rgba(0, 0, 0, .5);
          padding: 3px 10px 10px;

          label {
            display: block;
            font-size: 0.95em;
          }

          .ant-input {
            color: rgba(0, 0, 0, .5);
            padding: 6px 6px 8px;
          }

          ant-select {
            width: 100%;
          }

          textarea {
            // border-radius: 5px;
            // border: 1px rgba(0, 0, 0, .2) solid;
            // padding: 5px 10px;
            // width: 100%;

            &::-webkit-input-placeholder {
              color: rgba(0, 0, 0, .4);
            }

            &:-moz-placeholder { /* Firefox 18- */
              color: rgba(0, 0, 0, .4);
            }

            &::-moz-placeholder {  /* Firefox 19+ */
              color: rgba(0, 0, 0, .4);
            }

            &:-ms-input-placeholder {
              color: rgba(0, 0, 0, .4);
            }

            &::placeholder {
              color: rgba(0, 0, 0, .4);
            }
          }
        }
      }
    }
  }

    .page-header {
      .alert-score {
        background: rgba(0, 0, 0, .05);
        border-radius: 0;
        overflow: hidden;
        position: absolute;
        top: 0;
        left: 0;
        height: 44px;
        line-height: 44px;
        text-indent: 10px;
        width: 100%;
        color: rgba(0, 0, 0, .4);
  
        p {
          height: 44px;
          position: absolute;
          background: rgba(0, 0, 0, .3);
          right: 0;
          top: 0;
          width: 44px;
          font-size: 1em;
          padding: 0;
          color: white;
          text-align: center;
          line-height: 44px;
          position: relative;
  
          span {
            position: absolute;
            left: 50%;
            top: 50%;
            margin: -22px 0 0 -15px;
          }
        }
      }

      .meta-data-status {
        background: rgba(0, 0, 0, .005);
        height: 90px;
        left: 300px;
        top: 0;
        width: 300px;
        position: absolute;
        -webkit-box-shadow: inset 151px 0px 116px -146px rgba(0,0,0,0.1);
        -moz-box-shadow: inset 151px 0px 116px -146px rgba(0,0,0,0.1);
        box-shadow: inset 151px 0px 116px -146px rgba(0,0,0,0.1);
  
        ul {
          list-style-type: none;
          margin: 0;
          padding: 0;
  
          li {
            border: 1px rgba(0, 0, 0, .05) solid;
            color: rgba(0, 0, 0, .3);
            line-height: 30px;
            float: left;
            height: 45px;
            padding: 6px 20px;
            position: relative;
            width: 100%;
  
            label {
              margin: 0;
              padding: 0;
              float: left;
            }
  
            p {
              float:right;
              margin: 0;
              padding: 0;
              font-weight: bold;
            }
          }
        }
      }

      .meta-data {
        background: rgba(0, 0, 0, .005);
        height: 90px;
        -webkit-box-shadow: inset 151px 0px 116px -146px rgba(0,0,0,0.1);
        -moz-box-shadow: inset 151px 0px 116px -146px rgba(0,0,0,0.1);
        box-shadow: inset 151px 0px 116px -146px rgba(0,0,0,0.1);
        width: 300px;
        position: absolute;
        top: 0;
  
        &.first {
          right: 300px;
        }
  
        &:last-child {
          right: 0;
        }
  
        ul {
          list-style-type: none;
          margin: 0;
          padding: 0;
  
          li {
            border: 1px rgba(0, 0, 0, .05) solid;
            color: rgba(0, 0, 0, .3);
            line-height: 30px;
            float: left;
            height: 45px;
            padding: 6px 20px;
            position: relative;
            width: 100%;
  
            label {
              margin: 0;
              padding: 0;
              float: left;
            }
  
            p {
              float:right;
              margin: 0;
              padding: 0;
              font-weight: bold;
            }
          }
        }
      }
  }
}
