.modal-fichaCliente-register {
  .ant-modal {
    max-width: 1400px !important;
  }
  .modClientCard{
  // INICIO ESTILOS IMPORTADOS DESDE REGISTER ANTIGUO
    div.plantilla {
      float: left;
    }

    .section-wrapper {
      padding: 15px;
    }

    .cards-wrapper-mcc{
      padding: 10px 10px 20px 30px;
    }

    .radioBtn-risk-aml .ant-radio-button-wrapper {
      cursor: default;
    }

    div.ant-modal-body {
      padding: 5px 15px 15px 15px;

      h4 {
        background-color: #EEEEEE;
        padding: 5px;
      }
    }
    div.prop-div {
      div.ant-col:last-child {
        padding-left: 18px;
      }
    }
    input.read-only {
      background-color: #f5f9ff;
    }
    div.select-readonly div.ant-select-selection {
      background-color: #f5f9ff;
    }

    @media(max-width: 500px) {
      .ant-radio-group {
        width: 90%;
      }
    }

    .codeColumn{
      cursor: pointer;
    }

    .selected-alert-row{
      cursor: pointer;
    }

    .ant-form-item {
        margin-bottom: 0px;
        .ant-form-explain {
          display: none;
        }
    }
    .fecUpdate {
      display:inline-block;
      height: 30px;
      width: 100%;
      line-height: initial;
      border: 1px solid rgba(0,0,0,.2);
      padding: 3px 10px 3px 10px;
      background-color: rgba(245,245,245,0.3);
      border-radius: 3px;
    }
    .btn-add-replegal {
      float: right;
      margin: 10px 25px 10px 10px;
    }

    .tbl-related {
      table {
        border-collapse: collapse;

        td {
          vertical-align: top;
        }
      }

      .dni .error {
          display: none;
          font-size: 10px;
          color: red;
      }

      tr.no-valid {
        td .ant-select .ant-select-selection, td .ant-input {
            background-color: rgba(0,0,0,.1);
        }

        .dni {
          div.error {
            display: block;
          }

          input {
            border: 1px solid rgba(255,0,0,.5);
            background-color: rgba(255,0,0,.1);
          }
        }
      }
    }
  // FIN ESTILOS IMPORTADOS DESDE REGISTER ANTIGUO


      .field-section-row {
          margin-top: 20px;
      }

      .ant-form-item {
        margin-top: 5px;
        margin-bottom: 0px;

        .ant-form-item-label {
          line-height: 25px;
        }
        .ant-form-explain {
          display: none;
        }
      }

      .primario {
        background-color: #1890ff !important;
        border-color: #1890ff !important;
      }

      @keyframes append-animate-more-info {
        from {
          transform: scaleY(0);
          opacity: 0;
        }
        to {
          transform: scaleY(1);
          opacity: 1;	
        }
      }

      .div-more-info {
        transform-origin: 50% 0;
        animation: append-animate-more-info .2s linear;
      }

      .radioDecision {
        width: 50px;
        float: right;
        margin-right: 10px;

        &.no-btn {
          cursor: default;
        }
      }

      .ficha-register {
        .ant-input, .ant-input:hover, .ant-input:active, .ant-input:visited, .ant-input:link {
          border: 1px solid #d9d9d9 !important;
        }
      }


      .status-box{
          border: 1px solid #d9d9d9;
          border-radius: 8px;
          border-width: thin;
          padding: 0px 30px 20px 30px;
          margin-top: 20px;
          margin-bottom: 20px;
      }

      .riesgo-fields-section-wrapper{
        border: 1px solid #d9d9d9;
        border-radius: 5px;
        padding: 20px;

        .ant-col:last-child {
          .field-section-row-wrapper {
            //border-right: unset;
          }
        }
      }

      .title-section{
          background-color: #0A2D44;
          border-radius: 7px;
          padding: 8px 11px 0px 15px;
          h4{
            color:white;
          }

          a {
            color: unset;
          }
      }
      .onb-title-section{
        background-color: #be0e0e;
        border-radius: 7px;
        padding: 8px 11px 0px 15px;
        h4{
          color:white;
        }

        a {
          color: unset;
        }
    }

      .ant-table-pagination.ant-pagination{
        display: flex;
        justify-content: center;
        margin: 16px 0;
      }

      .ant-table-pagination{
        display: flex;
        justify-content: center;
        margin: 16px 0;
      }

      .header-card-onb-form{
          background-color: #0A2D44;
          padding: 10px 11px 11px 23px;
          border-radius: 6px;
          color: white;
      }

      .title-card-onb-form{
        text-align: center;
          color: #000000;
          background-color: #b5b5b529;
          padding: 7px;
          margin: 1px;
      }

      .ant-table-title{
        padding: 0;
      }

      .ant-table-wrapper{
        padding: 1px;
      }

      .ant-checkbox-inner::after {
        left: "20%";
      }


      .child-risk-onb-circle {
          border-radius: 102px;
          height: 37px;
          width: 37px;
          line-height: 35px;
          text-align: center;
          margin: 11px;
      }

      .formCard-onb-modal{
        background-color: #FAFFBD;
        border-radius: 4px;
        margin: 3px;
        text-align: center;
        vertical-align: middle;
      }

      .radioBtn-wrapper-onb{
        //display:flex;
        //justify-content:right;
      }

      .border-seemore{
          border-left: 1px solid #c9c5c5e6;
          border-right: 1px solid #c9c5c5e6;
          border-bottom: 1px solid #c9c5c5e6;
          border-bottom-left-radius: 30px;
          border-bottom-right-radius: 30px;
          border-width: thin;
          padding: 20px 30px 20px 30px;
          margin-top: 20px;
          padding-left: 30px;
          padding-right:30px;
      }

      .ant-radio-group{
        display: flex;
      }

      .radioGroupOnbRisk-ORANGE{
          .ant-radio-button-wrapper-checked{
              background: #FE9F0C !important;
              color: #fff !important;
          }
      }

      .radioGroupOnbRisk-YELLOW{
          .ant-radio-button-wrapper-checked{
              background: #fcdc6b !important;
              color: rgba(0, 0, 0, .3);
          }
      }

      .radioGroupOnbRisk-GREEN{
          .ant-radio-button-wrapper-checked{
              background: #598756 !important;
              color: #fff !important;
          }
      }

      .radioGroupOnbRisk-RED{
          .ant-radio-button-wrapper-checked{
              background: #d9534f !important;
              color: #fff !important;
          }
      }

      .radioGroupOnbRisk-BLACK{
          .ant-radio-button-wrapper-checked{
              background: rgba(0, 0, 0, 0.7) !important;
              color: #fff !important;
          }
      }

      .radioGroupOnbRisk-GRAY{
          .ant-radio-button-wrapper-checked{
              background: #999 !important;
              color: #fff !important;
          }
      }

      .ant-radio-button-wrapper{
        width:50%;
        margin-right: 8px;
        text-align: center;
        padding: 0;

        &.ant-radio-button-wrapper-checked {
          box-shadow: -1px 0 0 0 #1890ff;
        }
      }

      .risk-onb-title{
          background-color: #FAFFBD;
          border-radius: 7px;
          padding: 10px 47px 8px 16px;
          margin: 3px;
      }

      .col-contacto-onb{
        margin-top: 20px;
      }

      .item-risk-align{
        display: flex;
        justify-content: center;
      }

      .ant-table-thead > tr > th, .ant-table-tbody > tr > td{
        padding: 6px 12px;
      }

      .field-section-row-wrapper{
        border-right: 1px solid rgba(0,0,0,.05);
        border-left: 1px solid rgba(0,0,0,.05);
        border-bottom: 1px solid rgba(0,0,0,.05);
        height: 100%;
      }

      .progress-flat-wrapper{
        display: flex;
        justify-content: center;
        padding: 24px 50px 10px 50px;
      }

      .progress-button-wrapper{
        display: flex;
        justify-content: center;
        margin-bottom: 10px;
      }

      .porcent-onb-modal{
        background-color: #00000054;
        color: white;
        border-radius: 4px;
        text-align: center;
      }

      .prop-onb-modal{
        background-color: #171483;
        color: white;
        border-radius: 100%;
        text-align: center;
        width: 26px;
        height: 26px;
        line-height:22px;
        font-weight: bold;
      }

      .text-5mas-wrapper{
        display: flex;
        height: 177px;
        justify-content: center;
        align-items: center;
      }


      .table-col-wrapper-onb{
        .ant-table{
          .ant-table-placeholder{
            border-bottom: unset;
          }
        }
      }

      .block {
        //border: 1px rgba(0, 0, 0, .1) solid;
        margin-top: 20px;

        .block-title {
          position: relative;

          & > .anticon {
            display: none;
          }

          .ant-switch {
            position: absolute;
            right: 10px;
            top: 6px;
          }

          .save-button {
            background: green !important;
            border: 0 !important;
            margin-left: 350px;
            position: absolute;
            top: 0px;
          }
        }

        .block-content {
          position: relative;
          border: 1px rgba(0, 0, 0, .05) solid;

          .falses-positives {
              background: unset;

              .falses-positives-parents {
                .falses-positives-parent {
                  background:unset;
                  padding-left: 15px;
                }
              }
          }

          &.show {
            display: block;
          }

          &.hide {
            display: none;
          }

          .ant-col {
            color: rgba(0, 0, 0, .8);
            padding: 3px 10px 10px;

            label {
              display: block;
              font-size: 0.95em;
            }

            .ant-input {
              color: rgba(0, 0, 0, .5);
              padding: 6px 6px 8px;
            }

            ant-select {
              width: 100%;
            }

            textarea {
              border-radius: 5px;
              border: 1px rgba(0, 0, 0, .2) solid;
              padding: 5px 10px;
              width: 100%;

              &::-webkit-input-placeholder {
                color: rgba(0, 0, 0, .4);
              }

              &:-moz-placeholder { /* Firefox 18- */
                color: rgba(0, 0, 0, .4);
              }

              &::-moz-placeholder {  /* Firefox 19+ */
                color: rgba(0, 0, 0, .4);
              }

              &:-ms-input-placeholder {
                color: rgba(0, 0, 0, .4);
              }

              &::placeholder {
                color: rgba(0, 0, 0, .4);
              }
            }
          }
        }
      }
  }

}

.modal-detail-risk-alerts {
  .selected-alert-row-risk {
    cursor: pointer;
  }
}

.selected-alert-monitor-row{
  cursor: pointer;
}

.modal-client-previous-data {
  
  .ant-input, .ant-input:hover, .ant-input:active, .ant-input:visited, .ant-input:link {
    border: 1px solid #d9d9d9 !important;

    &.difference {
      border: 1px rgba(240,10,2,.7) solid !important;
    }
  }
  .ant-table-wrapper.difference {
    border: 1px rgba(240,10,2,.7) solid !important;
  }

  .ant-btn.difference {
    border: 1px rgb(0, 0, 0) solid !important;
    background: rgba(240, 10, 2, 0.6) !important;
  }
}