
#content{
  //padding-right: 300px !important;
}

.profile {
  width: 100%;
  overflow: hidden;

  .breadcrumbs {
    animation-duration: 0.1s;
    animation-fill-mode: forwards;
    animation-name: breadcrumbs-enter-after-transition;
    position: relative;
    animation-timing-function: ease;
  }

  .no-data-block {
    background: rgba(255, 255, 255, .5);
    border-radius: 5px;
    margin-bottom: 10px;
    padding: 20px;
    text-align: center;
  }

  .ant-tabs-tab {
    padding: 0 14px !important;
  }
}

@keyframes breadcrumbs-enter-after-transition {
  from {
    opacity: 0;
    right: 100%;
  }

  to {
    right: 0;
    opacity: 1;
  }
}

.green-dot {
  background: #45ad0d;
  border-radius: 2.5px;
  display: inline-block;
  height: 5px;
  margin-left: 5px;
  position: relative;
  top: -1px;
  width: 5px;
}
