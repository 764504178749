#pep-kycame {
  padding: 15px;

  div.personInfo:not(:last-child) {
    border-bottom: 20px solid #DAD9D9;
  }

  .nowrap {
    white-space: nowrap;
  }

  div.personInfo {
    div.ant-descriptions-view {
      border: 2px solid #DAD9D9;
      border-radius: 0px;

      th:first-child {
        width: 40%;
      }
    }
  }

  .table-wrapper {
    margin-bottom: 15px;

    .ant-table {
      border: 1px #ddd solid;
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
    }

    // &.pjud {
    //   h3 {
    //     background: rgba(0, 0, 0, .3);
    //   }

    //   thead.ant-table-thead {
    //     display: none;
    //   }

    //   tr.ant-table-row td {
    //     vertical-align: top;
    //   }

    //   tr.ant-table-row:not(:hover):nth-child(2n) td {
    //       background-color: #fbfbfb;
    //   }

    //   tr div.rutName {
    //     text-align: center;
    //   }
    //   tr div.title {
    //     color: rgba(0, 0, 0, .85);
    //     font-weight: 500;
    //   }
    // }
  }
}
