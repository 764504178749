
.short-card {
   height: 100%;

  .ant-card {
    height: 100%;
    border-radius: 10px;
  }

  .ant-card-head {
    border-bottom: 0px !important;
    padding-left: 10px !important;

  }

  .ant-card-body {
    padding: 0px 10px 10px 10px;
  }
}