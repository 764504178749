.modal-alert-card .task-history-main-section{
    padding: 10px;

    .comments-files{
        cursor: pointer;
    }

    .no-show-icon-class{
        .ant-table-row-expand-icon{
            display: none !important;
        }
    }
}