.admin-auth-ips {

  &.block {
    margin: 0 auto;
    width: 85%;
  }

  .theme-sepia .ant-descriptions .ant-descriptions-item-label {
    text-align: left;
    width: 40% !important;
  }

  .ant-descriptions-item-label {
    text-align: left;
    width: 30% !important;
  }

  .page-content {
    min-height: auto !important;
  }

  .authorized-ips {
    background: rgba(0, 0, 0, .5);
    border-radius: 5px;
    color: white;
    padding: 2px;
    height: 310px;
    width: 84%;

    .authorized-ips-inner {
      height: 300px;
      overflow-y: auto;

      display: flex;
      flex-direction: column;

      .authorized-ips-header {
        position: relative;
        display: flex;
        align-items: center;
        padding: 10px;
        border-bottom: 1px solid #ddd;
        background-color: #f9f9f9;
        position: sticky;
        top: 0;
        z-index: 1;

        .user-ip {
          width: 95%;
        }

        .add-button {
          background: green;
          border-radius: 12px;
          cursor: pointer;
          text-align: center;
          width: 24px;

          line-height: 24px;

        }
        .get-ip-button {
          border-radius: 12px;
          color: rgba(0, 0, 0, .5);
          cursor: pointer;
          text-align: right;
          width: 25px;
          margin-left: 40%;

          &:hover {
            background: rgba(0, 0, 0, .1);
          }
        }
      }
      ul {
        height: 240px;
        overflow-y: auto;
        margin: 0;
        padding-inline-start: 10px;

        &::-webkit-scrollbar-track {
          -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
          background-color: transparent;
        }

        &::-webkit-scrollbar-thumb {
          border-radius: 10px;
          -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
          background-color: rgba(0, 0, 0, .1);
        }

        &::-webkit-scrollbar {
          width: 8px;
          background-color: #F5F5F5;
        }

        li {
          border-bottom: 1px solid hsla(0, 0%, 100%, .1);
          margin-bottom: 5px;
          overflow: hidden;
          padding: 5px 5px 10px;
          position: relative;
          width: 95%;
          font-size: 16px;
        }

        .ip-address {
          float: left;
        }

        .remove {
          cursor: pointer;
          float: right;
        }
      }
    }
  }

  .send-button {
    margin: 15px auto 0;
  }
}
