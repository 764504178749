// .modal-alert-card{
    .task-revision-section-class{
        padding: 10px;
        .task-revision-body{
            padding: 20px;

            .tasks-revision-content-wrapper{
                border: 1px solid #d9d9d9;
                border-radius: 5px;
                padding: 25px;

                .save-button {
                    background: green !important;
                    border: 0 !important;
                    height: 28px;
                }

                .tasks-revision-module-wrapper{
                    border: 1px solid #1b18bb;
                    border-radius: 5px;
                    padding: 20px;
                    margin-bottom: 40px;

                    .task-revision-header{
                        background-color: #1b18bb;
                        color: white;
                        border-radius: 5px;
                        height: 28px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }

                    .task-revision-card-header{
                        background-color: #181688;
                        color: white;
                        border-radius: 5px 5px 0px 0px;
                        height: 28px;
                        display: flex;
                        align-items: center;
                        padding-left: 10px;
                    }

                    .task-revision-card-body{
                        border: 1px solid #d9d9d9;
                        border-radius: 0px 0px 5px 5px;
                        padding: 10px;
                        height: 90%;

                        p{
                            color: #181688;
                        }

                        // label{
                        //     border-bottom: 1px solid #d9d9d9;
                        // }
                    }
                }
            }
        }
    }
// }