.modal-risk {
  .onboarding {
    border-radius: 12px;
    height: 24px;
    width: 24px;
  }
}
.risk-chart {
  height: 200px;
  padding: 10px;
  overflow: hidden;
}
