.modal-view-simulation {
  border-radius: 10px;
  box-shadow: 0px 0px 49px 0px rgba(0,0,0,0.75);
  -webkit-box-shadow: 0px 0px 49px 0px rgba(0,0,0,0.75);
  -moz-box-shadow: 0px 0px 49px 0px rgba(0,0,0,0.75);
  padding: 0px;

  &.FINALIZED {
    top: 50px;
  }

  .ant-modal-close {
    height: auto !important;
    line-height: auto !important;
    padding: 0 !important;
    right: -8px;
    top: -8px;

    .ant-modal-close-x {
      height: auto !important;
      line-height: auto !important;
      padding: 0 !important;
    }
  }

  .ant-modal-body {
    padding: 0;

    .modal-view-simulation-header {
      -webkit-box-shadow: 0px 10px 12px 0px rgba(0,0,0,0.15);
-moz-box-shadow: 0px 10px 12px 0px rgba(0,0,0,0.15);
box-shadow: 0px 10px 12px 0px rgba(0,0,0,0.15);
      background: rgba(0, 0, 0, .5);
      text-align: center;

      h1 {
        color: rgba(255, 255, 255, .5);
        font-size: 1.5em;
        margin: 0;
        padding: 5px 0;
      }
    }

    .modal-view-simulation-body {
      padding: 15px;

      .modal-view-simulation-body-inner {
        border: 1px rgba(0, 0, 0, .2) solid;
        background: rgba(0, 0, 0, .1);
        border-radius: 10px;

        &.FINALIZED, &.APPLIED, &.DISCARDED {
          height: 550px;
          overflow-y: scroll;

          .modal-view-simulation-body-inner-inner {
            padding-left: 15px;
            padding-right: 15px;
          }
        }
      }
    }

    .modal-view-simulation-footer {
      background: rgba(0, 0, 0, .3);
      height: 50px;
      line-height: 50px;
      text-align: center;
    }
  }
}

.index-2 {
  padding: 0;

  .content-wrap-inner {
    height: 400px;

    .ant-col {
      padding: 0;
    }

    center {
      -webkit-box-shadow: 0px 19px 24px 0px rgba(0,0,0,0.12);
      -moz-box-shadow: 0px 19px 24px 0px rgba(0,0,0,0.12);
      box-shadow: 0px 19px 24px 0px rgba(0,0,0,0.12);
      background: rgba(252, 248, 3, .3);
      border: 1px rgba(0, 0, 0, .1) solid;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      font-size: 0.9em;
      margin-bottom: 40px;
      padding: 20px;
    }

    .progress-wrap {
      -webkit-box-shadow: inset 0px -143px 152px 0px rgba(0,0,0,0.21);
      -moz-box-shadow: inset 0px -143px 152px 0px rgba(0,0,0,0.21);
      box-shadow: inset 0px -143px 152px 0px rgba(0,0,0,0.21);
      border-radius: 10px;
      overflow: hidden;
      margin: 0 auto;

      .progress-wrap-inner {
        padding: 20px;

        .ant-progress {
          display: block;
          margin: 0 auto;
          text-align: center;

          svg {
          }
        }
      }

      .bottom-bar {
        background: rgba(0, 0, 0, .5);
        color: rgba(255, 255, 255, .5);
        padding: 10px 0;
        text-align: center;
      }
    }

    .progress-container {
      -webkit-box-shadow: inset 0px -143px 152px 0px rgba(0,0,0,0.21);
      -moz-box-shadow: inset 0px -143px 152px 0px rgba(0,0,0,0.21);
      border-radius: 10px;
      box-shadow: inset 0px -143px 152px 0px rgba(0,0,0,0.21);
      padding: 10px;
      border: 1px rgba(0, 0, 0, .2) solid;
      margin: 0 auto;
      width: 70%;
    }

    ul {
      list-style-type: none;
      padding: 0;
      margin: 0 0 0 10px;

      li {
        -webkit-box-shadow: inset 0px -143px 152px 0px rgba(0,0,0,0.05);
        -moz-box-shadow: inset 0px -143px 152px 0px rgba(0,0,0,0.05);
        box-shadow: inset 0px -143px 152px 0px rgba(0,0,0,0.05);
        background: rgba(0, 0, 0, .2);
        border-radius: 5px;
        font-size: 0.95em;
        margin: 0 0 5px;
        overflow: hidden;

        label {
          background: rgba(0, 0, 0, .2);
          color: rgba(255, 255, 255, .5);
          display: block;
          padding: 2px 10px;
        }

        p {
          color: rgba(255, 255, 255, .5);
          margin: 0;
          padding: 2px 10px;
        }
      }
    }
  }
}
