
.Alerts-by-user-asigned {

  .row-principal-content {
    padding-top: 8px;

    .alert-asigned-name {

      text-align: left;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;

      .asigned-name {
        font-size: 14px;
        color: #06068a;
      }

    }

    .full-advance-assign {
      width: 100%;
      background-color: coral;
      border-color: coral;
      font-size: 14px;
      height: 20px;

      .level-closed-alerts {
        float: left;
        padding-left: 5px;
        color: white;
      }

      .level-open-alerts {
        position: absolute;
        width: 100%;
        text-align: right;
        padding-right: 5px;
        color: white;
        cursor: pointer;
      }

    }

    .total-alerts-assign {
      font-size: 14px;
    }

    .level-advance-indicator {
      background: #5c96d3;
      color: white;
      height: 100%;
      cursor: pointer;
      z-index: 10;
      position: relative;

      .alert-completed-indicator {
        padding: 15px 0 15px 0;
        font-size: 17px;
        color: white;
        background: #5c96d3;

      }
    }
  }

  .row-percent-content {
    padding-top: 10px;

    .col-space {
      text-align: center;
      font-size: 14px;
      height: 40px;
    }
  }

  .row-info-color-label {

    .label-legend {
      position: relative;
      float: left;
      padding: 4%;
      margin-top: 2%;
    }

    .open {
      background: coral;
    }

    .closed {
      background: #5c96d3;
    }

    .label-content {
      float:left;
    }
  }

}