
.notification-card {

  .notification-count {
    font-size: 16px;
    text-decoration:  underline skyblue;
  }

  .ant-card-body {
    padding: 10px;
  }

  .left-card {
    left: 100%;
    height: 100%;
  }

  .right-card {
    right: 100%;
    height: 100%;
  }

  .search-rut {
    float: right;
  }
}