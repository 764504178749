
.ant-modal-footer {
  background-color: #EEEEEE !important;
  padding: 8px 16px 8px 16px !important;
  width: 100%;
  box-sizing: border-box;
  margin-top: 20px;
}

.folder-modal .ant-modal-body {
  padding: 1px;
}

.folder-input-container {
  display: flex;
  justify-content: center;
}

.folder-name-input {
  width: 90%;
  align-self: center;
  box-sizing: border-box;
}

.ant-form-split {
  color: #f5222d;
  margin-left: 5%;
}

.caracter-especial {
  color: #4594f1;
  font-size: 12px;
}

.ant-form-explain {
  margin-left: 6% !important;
}



