.register {
  .content {
    float: left;
    min-height: 1385px;
    width: calc(100% - 300px);
  }

  &.register-new {
    .content {
      min-height: 1100px;
      width: calc(100% - 260px);
    }
  }
}
