
.risk-list-resume-content {

  text-align: center;

  .list-value-risk {
    padding-top: 15px;
    padding-bottom: 5px;
    flex: 1;
    justify-content: center;
    align-items: center;
    align-content: center;


    .value-text {
      text-align: left;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;


      font-size: 14px;
      color: #06068a;
      padding-bottom: 10px;
    }

    .no-result {
      font-size: 15px;
      text-align: center;
    }

  }

  .title-list-risk {
    color: #06068a;
    font-weight: bold;
    font-size: 16px;
  }
}
