.final-beneficiaries {
  h3 {
    background: rgba(0, 0, 0, .3);
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    color: #fff;
    font-weight: bold;
    margin: 0;
    padding: 10px 0;
    text-align: center;
  }

  .table-wrapper {
    margin-bottom: 15px;
  }
}
.controllers {

}

.div-tooltip-pactos {
  max-width: 550px;

  table {
    background-color: rgba(255, 255, 255, .95);
    color: black;
    padding: 10px;

    tr.title {
      font-weight: bold;
    }
    td {
      border-bottom: 1px solid #e8e8e8;
      padding: 3px;
    }
  }
}
