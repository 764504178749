.basic-info-alert-section{
    padding: 10px;
    .subtitle-section{
        background-color: #0A2D44;
        border-radius: 7px;
        padding: 5px 0px 5px 12px;
        color:white;
        a {
            color: unset;
        }
    }

    .body-section-wrapper{
        padding: 0px 20px 0px 20px;
    }
}