.step3-onb{
    font-size: 20px;
    padding: 20px;

    .step3-transfer{
        margin-top: 20px;
    }

    .steps-buttons{
        margin-top: 30px;
    }
}
