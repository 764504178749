.onboarding .reporting-content{
    // .reporting-tabs{
    //     .ant-tabs-tab{
    //         background-color: blue !important;
    //         color: white !important;
            
    //     }
    //     .ant-tabs-tab-active{
    //         color: #fff;
    //         background-color: #a1a4a6;
    //     }
    // }
    .ant-tabs-bar{
        margin:unset;
    }
}