.modal-risk {
  .risk {
    border-radius: 12px;
    height: 24px;
    width: 24px;
  }
  .risk-chart {
    height: 250px;
    padding: 10px;
    overflow: visible;
  }
}

