#inside-compliance{
  .page-content{
    padding-left: 0;
  }

  #content{
    width: 100%;
    padding: 0px 10px 0px 10px;
  }
}
