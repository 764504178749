.profile {
    .page-header {
      background: none !important;
      box-shadow: none !important;
      height: auto !important;
      margin: 0 !important;
      padding: 50px 0 25px !important;

      .avatar {
        position: relative;
        background: rgba(0, 0, 0, .1) !important;
        border-radius: 65px !important;
        box-shadow: 0px 5px 1px 0px rgba(0,0,0,0.04);
        height: 130px !important;
        line-height: 130px !important;
        margin: 0 auto !important;
        position: relative;
        text-align: center !important;
        width: 130px !important;
        -moz-box-shadow: 0px 5px 1px 0px rgba(0,0,0,0.04);
        -webkit-box-shadow: 0px 5px 1px 0px rgba(0,0,0,0.04);
        overflow: hidden;
        z-index: 2;

        .image-wrapper-animated {
          animation-name: profile-image-appears;
          animation-duration: 1s;
          animation-fill-mode: forwards;

          img.iconAvatar {
            opacity: 0.7 !important;
          }
        }

        .image-wrapper {
          line-height: 130px;
          opacity: 0.5;

          img.icon {
            color: rgba(0, 0, 0, .1);
            height: 65px !important;
          }
        }
      }

      h1 {
        color: rgba(0, 0, 0, .5);
        font-size: 1.3em !important;
        line-height: 1.3em !important;
        margin: 0 !important;
        padding: 20px 20px 10px !important;
        text-align: center !important;
        text-transform: capitalize;
      }

      p {
        color: rgba(0, 0, 0, .5);
        text-align: center;
      }

      .anticon {
        display: none !important;
      }
    }
}

@keyframes profile-image-appears {
  from {
    opacity: 0;
    overflow: hidden;
  }
  to {
    opacity: 1;
    overflow: hidden;
  }
}
