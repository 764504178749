
.transfer-file {

  .admin-file-transfer {

    .ant-table-tbody {
      background-color: white;
    }

    display: flex;
    flex-direction: column;

    .tools-area {
      border-radius: 5px;
      margin-bottom: 20px;
      padding: 20px;
      text-align: right;
      position: sticky;
      top: 0;
      z-index: 1;
      background-color: white;
    }

    #btn-add-file {
      float: right;
    }

    .download-header-title {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
    }


    .table-container {
      flex: 1;
      overflow: auto;

      .ant-table-body {
        margin: 0 0px !important;
      }

      th, td {
        border-left: 1px solid #e8e8e8;
        border-right: 1px solid #e8e8e8;
        border-bottom: none;
        padding: 8px;
        text-align: left;
      }
    }
  }

  .file-manager-content {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 15%;
  }

  .dx-filemanager-dirs-tree.dx-treeview .dx-filemanager-file-actions-button {
    display: none;
  }

}
.ant-modal-body {
  .fix-content-modal {
    padding: 10px 23px;
  }
}