.results-bar {
  height: 45px;
  line-height: 45px;
  margin-bottom: 10px;
  overflow: hidden;

  .ant-pagination {
    float: right;
    position: relative;
    top: 2px;
  }

  .ant-col {
    height: 40px;
    display: flex;
    align-items: center;

    &:first-child {
      align-content: flex-start;
      text-align: left;
    }

    &:nth-child(2) {
      justify-content: center;
    }

    &:last-child {
      position: relative;

      .ant-pagination {
        font-size: 0.9em;
        position: absolute;
        right: 0;
        top: 7px;
      }
    }
  }
}
