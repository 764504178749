.cdi-relaciones .step2bd-content{
    padding: 20px;
    
    .step2bd-title{
        font-size: 20px;
        margin-bottom: 50px;
    }

    .step2bd-field-label{
        font-size: 16px;
        margin-bottom: -10px;
    }

    .step2bd-field-select{

    }
}
